import { FormControl, TextField, Grid, Autocomplete } from "@mui/material";
import axios from "axios";
import env from "../../../../env.json";
import { useEffect, useRef, useState } from "react";
import { Button } from "@material-ui/core";
import { useUserState } from "../../../../context/UserContext";
import ToolTipTable from "./toolTipTable";

export default function ContactGroupForm(props) {
  const {
    data,
    setOpen,
    showInfo,
    viewGroupContactList,
    setViewGroupContactList,
    setOpenSnackbar,
    setSnackbarMsg,
    setSnackbarSeverity,
  } = props;
  const { token } = useUserState();
  const [contactList, setContactList] = useState([]);
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [errorList, setErrorList] = useState([]);

  const groupNameRef = useRef();

  useEffect(() => {
    getContactListFunc();
  }, []);

  function getContactListFunc() {
    const request = {
      url: env.BaseURL + `api/user/messaging/contacts`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    axios(request)
      .then((response) => {
        if (response.data.status === 200) {
          setContactList(response.data.data);
        } else {
          setContactList([]);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const saveContactGroupFunc = () => {
    const request = {
      url: env.BaseURL + `api/user/messaging/contactgroups`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        group_name: groupNameRef.current?.value,
        c_id: selectedContacts.length > 0 ? selectedContacts : null,
      },
    };
    axios(request)
      .then((res) => {
        if (res.data.status === 200) {
          setOpen(false);
          setSnackbarSeverity("success");
          setSnackbarMsg("Contact Group saved successfully");
          setOpenSnackbar(true);
          setSelectedContacts([])
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.status === 406) {
          setErrorList(err.response.data.errors);
        } else {
          setErrorList([
            {
              msg: "Something went Wrong.",
              path: "",
            },
          ]);
        }
      });
  };

  const updateContactGroupFunc = () => {
    const request = {
      url: env.BaseURL + `api/user/messaging/contactgroups`,
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        g_id: data.g_id,
        group_name: groupNameRef.current?.value,
        c_id: selectedContacts,
      },
    };
    axios(request)
      .then((res) => {
        if (res.data.status === 200) {
          setOpen(false);
          setSnackbarSeverity("success");
          setSnackbarMsg("Contact Group updated successfully");
          setOpenSnackbar(true);
          setSelectedContacts([])
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.status === 406) {
          setErrorList(err.response.data.errors);
        } else {
          setErrorList([
            {
              msg: "Something went Wrong.",
              path: "",
            },
          ]);
        }
      });
  };

  return (
    <>
      <div
        style={{
          padding: "15px 15px 20px",
          margin: "10px 15px",
          width: "600px",
          overflow: "hidden",
        }}
      >
        <h3
          style={{
            color: "#4f4e4e",
            fontWeight: "500",
            marginBottom: "15px",
          }}
        >
          {data != null ? "Update Group" : "Create New Group"}
        </h3>
        <p className="errorMsgCommon">
          {errorList.length > 0 &&
            errorList.map((err) => {
              if (err.path === "") return <small>{err.msg}!</small>;
            })}
        </p>
        <Grid container spacing={4} mb={2}>
          <Grid item xs={12} sm={6} md={6}>
            <FormControl fullWidth>
              <TextField
                size="small"
                name="name"
                required
                label="Group Name"
                defaultValue={data?.group_name}
                inputRef={groupNameRef}
              />
            </FormControl>
            <p className="errorMsg">
              {errorList.length > 0 &&
                errorList.map((err) => {
                  if (err.path === "group_name")
                    return <small>{err.msg}!</small>;
                })}
            </p>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <FormControl fullWidth>
              <Autocomplete
                multiple
                id="tags-outlined"
                size="small"
                options={contactList?.filter((listItem) => {
                  return !viewGroupContactList.some(
                    (contact) => contact.mobile === listItem.mobile_no,
                  );
                })}
                getOptionLabel={(option) => option.name}
                filterSelectedOptions
                onChange={(e, value) => {
                  setSelectedContacts(value.map((id) => id.c_id));
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    label="Select Contacts"
                    placeholder="Select contacts"
                  />
                )}
              />
              <p className="errorMsg">
                {data === null &&
                  errorList.length > 0 &&
                  errorList.map((err) => {
                    if (err.path === "c_id") return <small>{err.msg}!</small>;
                  })}
              </p>
            </FormControl>
          </Grid>
        </Grid>
        {data != null ? (
          <ToolTipTable
            groupId={data?.g_id}
            showInfo={showInfo}
            viewGroupContactList={viewGroupContactList}
            setViewGroupContactList={setViewGroupContactList}
          />
        ) : null}
        <Grid mt={2} mb={2} style={{ float: "right" }}>
          <Button
            style={{
              fontWeight: "600",
              marginRight: "20px",
              color: "#ab0f0c",
            }}
            size="small"
            onClick={() => {
              setOpen(false);
            }}
          >
            Cancel
          </Button>
          <Button
            size="small"
            style={{
              color: "#008c53",
              fontWeight: "600",
            }}
            onClick={() => {
              data != null ? updateContactGroupFunc() : saveContactGroupFunc();
            }}
          >
            {data != null ? "Update" : "Create"}
          </Button>
        </Grid>
      </div>
    </>
  );
}
