import { FormControl, TextField, Grid, Card, Button } from "@mui/material";
import axios from "axios";
import { useRef, useState } from "react";
import env from "../../../../env.json";
import { useUserState } from "../../../../context/UserContext";
import { memo } from "react";

const Add = (props) => {
  const { data, setOpen, setOpenSnackbar, setSnackbarMsg, setSnackbarSeverity } = props;
  const { token } = useUserState();
  const [errorList, setErrorList] = useState([]);

  const templateNameRef = useRef();
  const templateBodyRef = useRef();

  const addTemplateFunc = (e) => {
    e.stopPropagation();
    const request = {
      url: env.BaseURL + `api/user/messaging/tamplate`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        temp_name: templateNameRef.current?.value,
        temp_text: templateBodyRef.current?.value,
      },
    };
    axios(request)
      .then((res) => {
        if (res.data.status === 200) {
          setOpen(false);
          setSnackbarSeverity("success")
          setSnackbarMsg("Template saved successfully");
          setOpenSnackbar(true);
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response?.data.status === 406) {
          setErrorList(err.response.data.errors);
        } else {
          setErrorList([
            {
              msg: "Something went Wrong.",
              path: "",
            },
          ]);
        }
      });
  };

  const updateTemplateFunc = (e) => {
    e.stopPropagation();
    const request = {
      url: env.BaseURL + `api/user/messaging/tamplate`,
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        id: data.t_id,
        temp_name: templateNameRef.current?.value,
        temp_text: templateBodyRef.current?.value,
      },
    };
    axios(request)
      .then((res) => {
        if (res.data.status === 200) {
          setOpen(false);
          setSnackbarSeverity("success")
          setSnackbarMsg("Template updated successfully");
          setOpenSnackbar(true);
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response?.data.status === 406) {
          setErrorList(err.response.data.errors);
        } else {
          setErrorList([
            {
              msg: "Something went Wrong.",
              path: "",
            },
          ]);
        }
      });
  };

  function sendText(e) {
    var selectionStart = templateBodyRef.current.selectionStart;
    var selectionEnd = templateBodyRef.current.selectionEnd;
    let textToInsert = "##" + e.target.value + "##";
    var newText =
      templateBodyRef.current?.value.substring(0, selectionStart) +
      textToInsert +
      templateBodyRef.current?.value.substring(selectionEnd);
    templateBodyRef.current.value = newText;
    templateBodyRef.current.selectionStart =
      selectionStart + textToInsert.length;
    templateBodyRef.current.selectionEnd = selectionStart + textToInsert.length;
    templateBodyRef.current.focus();
  }

  const fields = [
    { field: "NAME", value: "Name of Message Receiver" },
    { field: "CONTACT_NUMBER", value: "Number of Message Receiver." },
    { field: "CUSTOM_1", value: "Custom Field 1." },
    { field: "CUSTOM_2", value: "Custom Field 2." },
    { field: "CUSTOM_3", value: "Custom Field 3." },
  ];

  return (
    <>
      <Card style={{ padding: "20px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
            width: "100%",
          }}
        >
          <h3
            style={{
              color: "#4f4e4e",
              fontWeight: "500",
              margin: "0px 0px 15px",
            }}
          >
            {data != null ? "Edit" : "Create New"} Template
          </h3>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Button onClick={() => setOpen(false)} style={{ color: "#ab0f0c" }}>
              Cancel
            </Button>
            {data != null ? (
              <Button onClick={updateTemplateFunc} style={{ color: "#008c53" }}>
                Update
              </Button>
            ) : (
              <Button onClick={addTemplateFunc} style={{ color: "#008c53" }}>
                Add
              </Button>
            )}
          </div>
        </div>
        <p className="errorMsgCommon">
          {errorList.length > 0 &&
            errorList.map((err) => {
              if (err.path === "") return <small>{err.msg}!</small>;
            })}
        </p>
        <Grid container spacing={2}>
          <Grid item xs={5}>
            <FormControl fullWidth>
              <TextField
                size="small"
                required
                label="Template Name"
                placeholder="Enter Template Name"
                inputRef={templateNameRef}
                defaultValue={data?.temp_name}
              />
            </FormControl>
            <p className="errorMsg">
              {errorList.length > 0 &&
                errorList.map((err) => {
                  if (err.path === "temp_name")
                    return <small>{err.msg}!</small>;
                })}
            </p>
            <div
              style={{
                background: "#e7e7e7",
                padding: "5px",
                marginTop: "10px",
                width: "100%",
              }}
            >
              <h2
                style={{
                  color: "#4f4e4e",
                  fontWeight: "500",
                  margin: "5px 0px 10px",
                  fontSize: "16px",
                }}
              >
                Variables*
              </h2>
              {fields.map((item) => (
                <div
                  value={item.field}
                  style={{
                    width: "100%",
                    marginBottom: "10px",
                  }}
                >
                  <Button
                    value={item.field}
                    sx={{
                      padding: "0",
                      margin: "0",
                      width: "auto",
                      height: "fit-content",
                      minWidth: "0",
                      fontSize: "12px",
                      display: "flex",
                      flexWrap: "wrap",
                      textAlign: "left",
                      color: "#7a7a7a",
                    }}
                    onClick={sendText}
                  >
                    {item.field} : {item.value}
                  </Button>
                </div>
              ))}
            </div>
          </Grid>
          <Grid item xs={7} style={{ overflowY: "auto", height: "61vh" }}>
            <label
              style={{
                color: "#4f4e4e",
                fontWeight: "400",
                margin: "8px 0px",
                fontSize: "15px",
              }}
            >
              Template Text *
            </label>
            <p className="errorMsg">
              {errorList.length > 0 &&
                errorList.map((err) => {
                  if (err.path === "temp_text")
                    return <small>{err.msg}!</small>;
                })}
            </p>
            <FormControl fullWidth>
              <TextField
                placeholder="Start typing here..."
                inputRef={templateBodyRef}
                defaultValue={data?.temp_body}
                multiline
                minRows={13.8}
                color="warning"
              />
            </FormControl>
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

export default memo(Add);
