import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  moduleContainer: {
    padding: "15px 30px 30px",
  },
  inputField: {
    borderRadius: "5px",
    border: "1px solid #c7c6c6",
    background: "#f3f0f0",
    padding: "7px 10px",
    width: "250px",
  },
  imageField: {
    width: "60px",
    height: "40px",
    border: "1px solid #c7c6c6",
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    position: "relative",
  },
}));
