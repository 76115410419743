import { Button } from "@material-ui/core";
import PageTitle from "../../../../components/PageTitle";
import { useEffect, useState } from "react";
import InvoiceCard from "./invoiceCard";
import { useUserState } from "../../../../context/UserContext";
import {
  Grid,
  InputBase,
  Pagination,
  Tab,
  Tabs,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import axios from "axios";
import env from "../../../../env.json";
import UserPopover from "../../../../components/Popover/UserPopover";
import Add from "./add";
import UserSnackbar from "../../../../components/Snackbar.js/UserSnackbar";
import DeleteModal from "../../../../components/DeleteModal/DeleteModal";
import { Search } from "@material-ui/icons";
import ReceiveAmount from "./receiveAmt";

export default function Invoice(props) {
  const { isQrScan, qrMessage } = props;
  const { token } = useUserState();
  const [invoiceList, setInvoiceList] = useState([]);
  const [invoiceOpen, setInvoiceOpen] = useState(false);
  const [getInvoiceId, setGetInvoiceId] = useState(null);
  const [popoverEvent, setPopoverEvent] = useState(null);
  const [templates, setGetSelectedTemplate] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [propsData, setPropsData] = useState(null);
  const [itemCount, setItemCount] = useState(0);
  const [offset, setOffset] = useState(0);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [searchValue, setSearchValue] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteDone, setDeleteDone] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState(null);
  const [snackbarSeverity, setSnackbarSeverity] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [alignment, setAlignment] = useState("0");

  const open = Boolean(popoverEvent);
  const id = open ? "popover-textfield" : undefined;

  const handleChange = (event, value) => {
    if (value !== null) {
      setOffset(0);
      setAlignment(value);
    }
  };
  useEffect(() => {
    getInvoicedata();

    if (!invoiceOpen) {
      setPopoverEvent(null);
      setGetInvoiceId(null);
    }
  }, [invoiceOpen, offset, searchValue, alignment]);

  useEffect(() => {
    if (deleteDone) {
      setOpenDeleteModal(false);
      deleteContact(getInvoiceId);
    }
    if (getInvoiceId)
      setPropsData(invoiceList.find((item) => item.id == getInvoiceId));
    else setPropsData(null);
  }, [deleteDone, getInvoiceId]);

  const invoiceForm = !isEdit ? (
    <Add
      setOpen={setInvoiceOpen}
      templates={templates}
      companies={companies}
      data={propsData}
      offset={offset}
      setSnackbarMsg={setSnackbarMsg}
      setSnackbarSeverity={setSnackbarSeverity}
      setOpenSnackbar={setOpenSnackbar}
    />
  ) : (
    <ReceiveAmount
      setOpen={setInvoiceOpen}
      data={propsData}
      offset={offset}
      setSnackbarMsg={setSnackbarMsg}
      setSnackbarSeverity={setSnackbarSeverity}
      setOpenSnackbar={setOpenSnackbar}
    />
  );

  const getInvoicedata = () => {
    const requestInvoices = {
      url:
        env.BaseURL +
        (alignment == "0"
          ? `api/user/payment/invoices?offset=${offset}${
              searchValue ? "&value=" + searchValue : ""
            }`
          : `api/user/payment/completedinvoices?offset=${offset}${
              searchValue ? "&value=" + searchValue : ""
            }`),
      method: "Get",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    axios(requestInvoices)
      .then((response) => {
        if (response.data.status === 200) {
          setItemCount(response.data.count);
          setInvoiceList(response.data.data);
          console.log('here')
        } else {
          setInvoiceList([]);
        }
      })
      .catch((error) => {
        console.error(error);
      });

    const requestTemplates = {
      url: env.BaseURL + `api/user/payment/tamplate`,
      method: "Get",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    axios(requestTemplates)
      .then((response) => {
        if (response.data.status === 200) {
          setGetSelectedTemplate(response.data.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });

    const requestCompanies = {
      url: env.BaseURL + `api/user/payment/companies`,
      method: "Get",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    axios(requestCompanies)
      .then((response) => {
        if (response.data.status === 200) {
          setCompanies(response.data.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  function deleteContact(id) {
    const request = {
      url: env.BaseURL + `api/user/payment/invoices?offset=${offset}`,
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        id: id,
      },
    };
    axios(request)
      .then((res) => {
        setDeleteDone(false);
        getInvoicedata();
        setGetInvoiceId(null);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  return (
    <>
      <PageTitle
        title="INVOICES"
        button={
          <Button
            variant="contained"
            size="small"
            color="primary"
            aria-describedby={id}
            onClick={(event) => {
              if (!isQrScan) {
                setSnackbarSeverity("error");
                setSnackbarMsg(qrMessage);
                setOpenSnackbar(true);
              } else {
                setPopoverEvent(event.currentTarget);
                setInvoiceOpen(true);
                setGetInvoiceId(null);
              }
            }}
          >
            Create Invoice
          </Button>
        }
        searching={
          <div className="search">
            <Search className="searchIcon" />
            <InputBase
              onChange={(e) => setSearchValue(e.target.value)}
              placeholder="Search…"
              className="searchInput"
            />
          </div>
        }
      />
      <Grid container spacing={1} sx={{ padding: "0 5px 20px 10px" }}>
        <Grid item xs={12} md={6}>
          <ToggleButtonGroup
            value={alignment}
            exclusive
            onChange={handleChange}
            aria-label="Platform"
            size="small"
          >
            <ToggleButton value="0">Active</ToggleButton>
            <ToggleButton value="1">Completed</ToggleButton>
          </ToggleButtonGroup>
        </Grid>
        {invoiceList.length > 0 && (
          <Grid item xs={12} md={6}>
            <Pagination
            page={(offset)/12+1}
              count={itemCount}
              onChange={(e, v) => setOffset((v - 1) * 12)}
              color="primary"
              style={{ float: "right", margin: 0 }}
            />
          </Grid>
        )}
      </Grid>

      <Grid
        container
        spacing={2}
        style={{ padding: "0 30px 30px", overflowY: "auto", height: "65vh" }}
      >
        {invoiceList.length > 0 ? (
          invoiceList.map(
            (value) =>
              (value.total_amount !== 0 || alignment == "1") && (
                <Grid key={value.id} item xs={12} sm={4} md={3}>
                  <InvoiceCard
                    {...value}
                    setOpenDeleteModal={setOpenDeleteModal}
                    setGetId={setGetInvoiceId}
                    openModal={setPopoverEvent}
                    setInvoiceOpen={setInvoiceOpen}
                    isQrScan={isQrScan}
                    setIsEdit={setIsEdit}
                  />
                </Grid>
              ),
          )
        ) : (
          <p style={{ marginLeft: "45%", marginTop: "20%" }}>No data found</p>
        )}
      </Grid>
      <DeleteModal
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        setDone={setDeleteDone}
      />
      <UserPopover
        id={id}
        open={open}
        form={invoiceForm}
        setOpen={setInvoiceOpen}
        popoverEvent={popoverEvent}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      />
      <UserSnackbar
        severity={snackbarSeverity}
        open={openSnackbar}
        setClose={setOpenSnackbar}
        message={snackbarMsg}
      />
    </>
  );
}
