import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export default function DeleteModal(props) {
  const { open, setOpen, setDone } = props;

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Delete"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to delete this.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button sx={{ fontWeight: 600 }} onClick={handleClose}>
          Cancel
        </Button>
        <Button
          sx={{ fontWeight: 600 }}
          onClick={() => {
            setDone(true);
          }}
          autoFocus
          color="error"
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}
