import React from "react";
import { useTheme } from "@material-ui/styles";

// styles
import useStyles from "./styles";

// components
import { Typography } from "../Wrappers";

export default function UserAvatar({ color = "primary", ...props }) {
  var classes = useStyles();
  var theme = useTheme();

  var letters = props.name
    ? props.name
        .split(" ")
        .map((word) => word[0])
        .join("")
    : "";

  return (
    <div
      className={classes.avatar}
      style={{ backgroundColor: props.bgcolor || theme.palette[color].main }}
    >
      <Typography className={classes.text}>
        {letters.substring(0, 2)}
      </Typography>
    </div>
  );
}
