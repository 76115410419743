import {
  Autocomplete,
  FormControl,
  TextField,
  Grid,
  Card,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import axios from "axios";
import { useCallback, useEffect } from "react";
import { useState } from "react";
import env from "../../../env.json";
import { useUserState } from "../../../context/UserContext";
import dayjs from "dayjs";

export default function AddUser({
  clientDetail,
  setClientDetail,
  setAddNewClient,
  addNewClient,
}) {
  const { token } = useUserState();
  const [getModuleList, setGetModuleList] = useState([]);

  const currentDate = dayjs();

  useEffect(() => {
    setAddNewClient({
      ...addNewClient,
      valid_upto: currentDate.format("YYYY-MM-DD"),
    });
    const requestModuleList = {
      url: env.BaseURL + `api/admin/allmodules`,
      method: "Get",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    axios(requestModuleList)
      .then((response) => {
        if (response.data.status === 200) {
          setGetModuleList(response.data.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const addNewClientDetailFunc = useCallback((event) => {
    const { name, value } = event.target;
    setAddNewClient((prev) => ({
      ...prev,
      [name]: value,
    }));
  });

  return (
    <div style={{ height: "68vh", overflowY: "auto" }}>
      <Card style={{ padding: "15px 15px 20px", margin: "30px" }}>
        <h3
          style={{
            color: "#4f4e4e",
            fontWeight: "500",
            margin: "0px 0px 15px",
          }}
        >
          Create New Client
        </h3>
        <Grid container spacing={4}>
          <Grid item xs={6} sm={3.9} md={3}>
            {getModuleList.length && <FormControl fullWidth>
              <Autocomplete
                multiple
                defaultValue={
                  clientDetail?.modules != null && clientDetail?.modules != ""
                    ? getModuleList.filter((item) =>
                        clientDetail.modules.split(",").includes(item.name),
                      )
                    : []
                }
                id="size-small-outlined-multi"
                size="small"
                filterSelectedOptions
                options={getModuleList}
                getOptionLabel={(option) => option?.name}
                onChange={(e, value) => {
                  console.log(value, "value : ");
                  if (clientDetail != null) {
                    setClientDetail({
                      ...clientDetail,
                      m_id: value.map((val) => val.m_id).join(","),
                    });
                  } else {
                    setAddNewClient({
                      ...addNewClient,
                      m_id: value.map((val) => val.m_id).join(","),
                    });
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Module"
                    placeholder="Select Module"
                  />
                )}
              />
            </FormControl>}
          </Grid>
          <Grid item xs={6} sm={3.9} md={3}>
            <FormControl fullWidth>
              <TextField
                size="small"
                name="client_name"
                label="Client Name"
                value={clientDetail && clientDetail.client_name}
                required
                placeholder="Enter Client Name"
                onChange={(e) => {
                  if (clientDetail != null) {
                    setClientDetail({
                      ...clientDetail,
                      client_name: e.target.value,
                    });
                  } else {
                    addNewClientDetailFunc(e);
                  }
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} sm={3.9} md={3}>
            <FormControl fullWidth>
              <TextField
                size="small"
                name="phone"
                label="Contact No"
                value={clientDetail && clientDetail.phone}
                placeholder="Enter Contact No."
                onChange={(e) => {
                  if (clientDetail != null) {
                    setClientDetail({
                      ...clientDetail,
                      phone: e.target.value,
                    });
                  } else {
                    addNewClientDetailFunc(e);
                  }
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} sm={3.9} md={3}>
            <FormControl fullWidth>
              <TextField
                size="small"
                name="email"
                label="Email"
                placeholder="Enter Email"
                value={clientDetail && clientDetail.email}
                onChange={(e) => {
                  if (clientDetail != null) {
                    setClientDetail({
                      ...clientDetail,
                      email: e.target.value,
                    });
                  } else {
                    addNewClientDetailFunc(e);
                  }
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} sm={3.9} md={3}>
            <FormControl fullWidth>
              <TextField
                size="small"
                name="gst_no"
                label="GST No"
                value={clientDetail && clientDetail.gst_no}
                placeholder="Enter GST No."
                onChange={(e) => {
                  if (clientDetail != null) {
                    setClientDetail({
                      ...clientDetail,
                      gst_no: e.target.value,
                    });
                  } else {
                    addNewClientDetailFunc(e);
                  }
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} sm={3.9} md={3}>
            <FormControl fullWidth>
              <TextField
                size="small"
                name="c_in"
                label="C IN"
                placeholder="Enter C IN"
                value={clientDetail && clientDetail.c_in}
                onChange={(e) => {
                  if (clientDetail != null) {
                    setClientDetail({ ...clientDetail, c_in: e.target.value });
                  } else {
                    addNewClientDetailFunc(e);
                  }
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} sm={3.9} md={3}>
            <FormControl fullWidth>
              <TextField
                size="small"
                name="pan"
                label="PAN"
                placeholder="Enter PAN No."
                value={clientDetail && clientDetail.pan}
                onChange={(e) => {
                  if (clientDetail != null) {
                    setClientDetail({ ...clientDetail, pan: e.target.value });
                  } else {
                    addNewClientDetailFunc(e);
                  }
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} sm={3.9} md={3}>
            <FormControl fullWidth>
              <TextField
                size="small"
                name="contact_person"
                label="Contact Person"
                value={clientDetail && clientDetail.contact_person}
                placeholder="Enter Contact Person"
                onChange={(e) => {
                  if (clientDetail != null) {
                    setClientDetail({
                      ...clientDetail,
                      contact_person: e.target.value,
                    });
                  } else {
                    addNewClientDetailFunc(e);
                  }
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} sm={3.9} md={3}>
            <FormControl fullWidth>
              <TextField
                size="small"
                name="address"
                label="Address"
                value={clientDetail && clientDetail.address}
                placeholder="Enter Address"
                onChange={(e) => {
                  if (clientDetail != null) {
                    setClientDetail({
                      ...clientDetail,
                      address: e.target.value,
                    });
                  } else {
                    addNewClientDetailFunc(e);
                  }
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} sm={3.9} md={3}>
            <FormControl fullWidth>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  defaultValue={dayjs(clientDetail && clientDetail.valid_upto)}
                  label="Valid Upto *"
                  onChange={(e) => {
                    if (clientDetail != null) {
                      setClientDetail({
                        ...clientDetail,
                        valid_upto: e.format("YYYY-MM-DD"),
                      });
                    } else {
                      setAddNewClient({
                        ...addNewClient,
                        valid_upto: e.format("YYYY-MM-DD"),
                      });
                    }
                  }}
                  format="DD-MM-YYYY"
                  slotProps={{ textField: { size: "small" } }}
                />
              </LocalizationProvider>{" "}
            </FormControl>
          </Grid>
        
            <Grid item xs={6} sm={3.9} md={3}>
              <FormControl fullWidth>
                <TextField
                  disabled={clientDetail && true}
                  size="small"
                  name="channel_id"
                  label="Channel Id (Do not update)"
                  value={clientDetail && clientDetail.channel_id}
                  onChange={(e) => {
                    if (clientDetail != null) {
                      setClientDetail({
                        ...clientDetail,
                        channel_id: e.target.value,
                      });
                    } else {
                      addNewClientDetailFunc(e);
                    }
                  }}
                />
              </FormControl>
            </Grid>
        </Grid>
      </Card>
      <Card style={{ padding: "15px 15px 20px", margin: "30px" }}>
        <h3
          style={{
            color: "#4f4e4e",
            fontWeight: "500",
            margin: "0px 0px 15px",
          }}
        >
          Messaging API Detail
        </h3>
        <Grid container spacing={4}>
          <Grid item xs={6} sm={3.9} md={3}>
            <FormControl fullWidth>
              <TextField
                size="small"
                name="api_userid"
                label="API UserId"
                value={clientDetail && clientDetail.api_userid}
                onChange={(e) => {
                  if (clientDetail != null) {
                    setClientDetail({
                      ...clientDetail,
                      api_userid: e.target.value,
                    });
                  } else {
                    addNewClientDetailFunc(e);
                  }
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} sm={3.9} md={3}>
            <FormControl fullWidth>
              <TextField
                size="small"
                name="api_password"
                label="API Password"
                value={clientDetail && clientDetail.api_password}
                onChange={(e) => {
                  if (clientDetail != null) {
                    setClientDetail({
                      ...clientDetail,
                      api_password: e.target.value,
                    });
                  } else {
                    addNewClientDetailFunc(e);
                  }
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} sm={3.9} md={3}>
            <FormControl fullWidth>
              <TextField
                size="small"
                name="api_mobile"
                label="API Mobile"
                value={clientDetail && clientDetail.api_mobile}
                onChange={(e) => {
                  if (clientDetail != null) {
                    setClientDetail({
                      ...clientDetail,
                      api_mobile: e.target.value,
                    });
                  } else {
                    addNewClientDetailFunc(e);
                  }
                }}
              />
            </FormControl>
          </Grid>
          {clientDetail != null ? (
            <Grid item xs={6} sm={3.9} md={3}>
              <FormControl fullWidth>
                <TextField
                  disabled
                  size="small"
                  name="auth_key"
                  label="Auth Key"
                  value={clientDetail && clientDetail.auth_key}
                />
              </FormControl>
            </Grid>
          ) : null}
        </Grid>
      </Card>
      <Card style={{ padding: "15px 15px 20px", margin: "30px 30px 5px" }}>
        <h3
          style={{
            color: "#4f4e4e",
            fontWeight: "500",
            margin: "0px 0px 15px",
          }}
        >
          User Detail
        </h3>
        <Grid container spacing={4}>
          <Grid item xs={6} sm={3.9} md={3}>
            <FormControl fullWidth>
              <TextField
                size="small"
                name="username"
                required
                label="User Name"
                placeholder="Enter User Name"
                value={clientDetail && clientDetail.username}
                onChange={(e) => {
                  if (clientDetail != null) {
                    setClientDetail({
                      ...clientDetail,
                      username: e.target.value,
                    });
                  } else {
                    addNewClientDetailFunc(e);
                  }
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} sm={3.9} md={3}>
            <FormControl fullWidth>
              <TextField
                required
                size="small"
                name="password"
                label="Password"
                placeholder="Enter Password"
                onChange={(e) => {
                  if (clientDetail != null) {
                    setClientDetail({
                      ...clientDetail,
                      password: e.target.value,
                    });
                  } else {
                    addNewClientDetailFunc(e);
                  }
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} sm={3.9} md={3}>
            <FormControl fullWidth>
              <TextField
                required
                size="small"
                name="repeat_password"
                label="Repeat-password"
                placeholder="Enter Password"
                onChange={(e) => {
                  if (clientDetail != null) {
                    setClientDetail({
                      ...clientDetail,
                      repeat_password: e.target.value,
                    });
                  } else {
                    addNewClientDetailFunc(e);
                  }
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Card>
    </div>
  );
}
