import { FormControl, Grid } from "@material-ui/core";
import PageTitle from "../../../components/PageTitle/PageTitle";
import { Button, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import env from "../../../env.json";
import { useUserState } from "../../../context/UserContext";
import { LocalizationProvider, MobileTimePicker } from "@mui/x-date-pickers";
import UserSnackbar from "../../../components/Snackbar.js/UserSnackbar";

export default function Settings() {
  const { token, user } = useUserState();
  const [getModuleList, setGetModuleList] = useState([]);
  const [getTimeList, setGetTimeList] = useState([]);
  const [snackbarMsg, setSnackbarMsg] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState(null);

  const modulesHaveTimer = ["1"];
  const dateTimeRef = useRef(null);

  useEffect(() => {
    getTimeListWithModuleFunc();
  }, []);

  const getTimeListWithModuleFunc = () => {
    const requestModuleList = {
      url: env.BaseURL + `api/allmodules`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    axios(requestModuleList)
      .then((response) => {
        if (response.data.status === 200) {
          let result = response.data.data;
          let module = user.m_id.split(",");
          setGetModuleList(
            result.filter((item) => module.includes(item.m_id.toString())),
          );
        }
      })
      .catch((error) => {
        console.error(error);
      });

    const request = {
      url: env.BaseURL + `api/user/setting`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    axios(request)
      .then((response) => {
        if (response.data.status === 200) {
          setGetTimeList(response.data.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const saveTimeFunc = (m_id, id) => {
    const request = {
      url: env.BaseURL + `api/user/setting`,
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        id: id,
        m_id: m_id,
        cron_run_time: dayjs(dateTimeRef.current?.value, "hh:mm A").format(
          "HH",
        ),
        timezone: "",
      },
    };
    axios(request)
      .then((response) => {
        if (response.data.status === 200) {
          setSnackbarSeverity("success");
          setSnackbarMsg("Schedule time updated successfully");
          setOpenSnackbar(true);
          getTimeListWithModuleFunc();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const addTimeFunc = (m_id) => {
    const request = {
      url: env.BaseURL + `api/user/setting`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        m_id: m_id,
        cron_run_time: dayjs(dateTimeRef.current?.value, "hh:mm A").format(
          "HH",
        ),
        timezone: "",
      },
    };
    axios(request)
      .then((response) => {
        if (response.data.status === 200) {
          setSnackbarSeverity("success");
          setSnackbarMsg("Schedule time added successfully");
          setOpenSnackbar(true);
          getTimeListWithModuleFunc();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <>
      <PageTitle title="Settings" />
      <div style={{ margin: "15px", backgroundColor: "white", height: "70vh" }}>
        <Grid container spacing={3} md={11} style={{ margin: "15px" }}>
          {getModuleList
            .filter((item) => modulesHaveTimer.includes(item.m_id.toString()))
            .map((val) => {
              return (
                val.m_id && (
                  <>
                    <Grid
                      item
                      xs={12}
                      sm={3.9}
                      md={4}
                      style={{ display: "flex", alignItems: "flex-end" }}
                    >
                      <Typography sx={{ fontSize: "18px" }}>
                        {val.name}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={3.9}
                      md={4}
                      style={{ display: "flex", alignItems: "flex-end" }}
                    >
                      <FormControl>
                        <p style={{ margin: 0 }}>Schedule Time</p>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <MobileTimePicker
                            slotProps={{ textField: { size: "small" } }}
                            value={dayjs()
                              .hour(
                                Number(
                                  getTimeList.find(
                                    (item) => item.mod_id === val.m_id,
                                  )?.cron_run_time,
                                ),
                              )
                              .minute(0)
                              .second(0)}
                            minutesStep={60}
                            format="hh:mm A"
                            ampm="true"
                            inputRef={dateTimeRef}
                          />
                        </LocalizationProvider>
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={3.9}
                      md={2}
                      style={{ display: "flex", alignItems: "flex-end" }}
                    >
                      <Button
                        variant="contained"
                        onClick={(e) => {
                          if (
                            getTimeList.length > 0 &&
                            getTimeList.find((item) => item.mod_id === val.m_id)
                          ) {
                            saveTimeFunc(
                              val.m_id,
                              getTimeList.find(
                                (item) => item.mod_id === val.m_id,
                              )?.t_id,
                            );
                          } else {
                            addTimeFunc(val.m_id);
                          }
                        }}
                      >
                        SAVE
                      </Button>
                    </Grid>
                  </>
                )
              );
            })}
        </Grid>
      </div>
      <UserSnackbar
        severity={snackbarSeverity}
        open={openSnackbar}
        setClose={setOpenSnackbar}
        message={snackbarMsg}
      />
    </>
  );
}
