import { Alert, Fab, Snackbar, Tab, Tabs } from "@mui/material";
import React, { useEffect } from "react";
import ContactGroups from "./contactGroup";
import { useState } from "react";
import { Suspense } from "react";
import WhatsappGroups from "./whatsappGroup";
import "./style.css";
import { Send } from "@material-ui/icons";
import UserPopover from "../../../../components/Popover/UserPopover";
import SendMessage from "./sendMessage";

const GmContacts = React.lazy(() => import("./contacts"));

export default function ContactTabs(props) {
  const { isQrScan, qrMessage } = props;
  const [value, setValue] = useState(0);
  const [popoverEvent, setPopoverEvent] = useState(null);
  const [popoverDeps, setPopoverDeps] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState(null);
  const [severity, setSeverity] = useState(null);

  const open = Boolean(popoverEvent);
  const id = open ? "popover-textfield" : undefined;
  console.log("tab");
  useEffect(() => {
    if (!popoverDeps) {
      setPopoverEvent(null);
    }
  }, [popoverDeps]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const SendMessageForm = (
    <SendMessage
      index={value}
      setPopoverDeps={setPopoverDeps}
      setSeverity={setSeverity}
      setSnackbarMsg={setSnackbarMsg}
      setOpenSnackbar={setOpenSnackbar}
    />
  );

  return (
    <>
      <Tabs
        value={value}
        onChange={handleChange}
        variant="fullWidth"
        aria-label="basic tabs example"
        sx={{ marginBottom: "5px", color: "grey" }}
      >
        <Tab
          className="tabHeading"
          label={<h3>My Contacts</h3>}
          {...a11yProps(0)}
        />
        <Tab
          className="tabHeading"
          label={<h3>Contact Groups</h3>}
          {...a11yProps(1)}
        />
        <Tab
          className="tabHeading"
          label={<h3>Whatsapp Groups</h3>}
          {...a11yProps(2)}
        />
      </Tabs>
      <Suspense fallback={<h1>Loading...</h1>}>
        <GmContacts value={value} index={0} />
      </Suspense>
      <ContactGroups value={value} index={1} />
      <WhatsappGroups value={value} index={2} />
      <Fab
        sx={{
          fontSize: "16px",
          padding: "10px 15px",
          position: "fixed",
          bottom: "30px",
          right: "-140px",
          transition: "1s all",
          "&:hover": {
            right: "0.5%",
          },
        }}
        color="primary"
        size="medium"
        variant="extended"
        aria-label="add"
        onClick={(event) => {
          if (!isQrScan) {
            setSeverity("info");
            setSnackbarMsg("Please scan the QR Code!");
            setOpenSnackbar(true);
          } else {
            setPopoverEvent(event.currentTarget);
            setPopoverDeps(true);
          }
        }}
      >
        <Send style={{ width: "17px", height: "17px", marginRight: "5px" }} />
        Send Message
      </Fab>
      <UserPopover
        id={id}
        open={open}
        form={SendMessageForm}
        setOpen={setPopoverDeps}
        popoverEvent={popoverEvent}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      />
      <Snackbar
        className={
          severity == "error"
            ? "snackbarError"
            : severity == "info"
            ? "snackbarInfo"
            : "snackbarSuccess"
        }
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert
          onClose={() => setOpenSnackbar(false)}
          severity={severity}
          sx={{ width: "100%" }}
        >
          {snackbarMsg && snackbarMsg}
        </Alert>
      </Snackbar>
    </>
  );
}
