import {
  Box,
  Button,
  CardActions,
  CardContent,
  CardHeader,
  Modal,
  Typography,
} from "@mui/material";
import { Cancel, Create, Delete } from "@material-ui/icons";
import { Card } from "@material-ui/core";

export default function TemplateModal(props) {
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "fit-content",
    bgcolor: "none",
    border: "none",
    maxHeight: "70vh",
    overflowY: "auto",
  };

  return (
    <Modal
      open={props.open}
      onClose={() => props.setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle} className="templateModalBox">
        <Cancel
          onClick={() => props.setOpen(false)}
          style={{
            color: "#536dfe",
            cursor: "pointer",
            right: "5px",
            top: "5px",
            position: "absolute",
            zIndex: "9",
          }}
        />
        <div className="cardContainer">
          <Card
            sx={{ width: "100%", cursor: "pointer" }}
            elevation={0}
            className="note"
          >
            <CardHeader style={{ padding: "10px" }} title={props.title} />
            <CardContent style={{ padding: "3px 10px 10px" }}>
              <Typography
                sx={{
                  mb: "5px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <small className="cardContent">{props.data}</small>
              </Typography>
            </CardContent>
            <CardActions style={{ float: "right" }}>
              <Button
                sx={{
                  fontSize: "14px",
                  fontWeight: "600",
                }}
                aria-describedby={props.id}
                onClick={(event) => {
                  event.stopPropagation();
                  props.setPopoverEvent(true);
                  props.setPopoverDeps(true);
                  props.setIsEdit(true);
                  props.setOpen(false);
                }}
              >
                <Create
                  style={{
                    width: "20px",
                    height: "20px",
                    marginRight: "5px",
                  }}
                />
                Edit
              </Button>
              <Button
                sx={{
                  fontSize: "14px",
                  fontWeight: "600",
                }}
                onClick={() => {
                  props.setOpenDeleteModal(true);
                }}
              >
                <Delete
                  style={{
                    width: "20px",
                    height: "20px",
                    marginRight: "5px",
                  }}
                />
                Delete
              </Button>
            </CardActions>
          </Card>
        </div>
      </Box>
    </Modal>
  );
}
