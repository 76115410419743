import React from "react";
import env from "../env.json";
import axios from "axios";

const UserStateContext = React.createContext();
const UserDispatchContext = React.createContext();

function userReducer(state, action) {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return {
        ...state,
        isAuthenticated: true,
        token: action.token,
        user: action.user,
        is_varify: true,
        is_scan: false,
        qr_message:'',
      };
    case "LOGIN_FAILURE":
      return { ...state, isAuthenticated: false, token: "", user: null };
    case "SIGN_OUT_SUCCESS":
      return { ...state, isAuthenticated: false, token: "", user: null };
    case "QR_SCAN":
      return {...state, is_scan:action.is_scan, qr_message:action.qr_message }
    case "VERYFY_TOKEN":
      return {
        ...state,
        isAuthenticated: action.is_login,
        token: action.token,
        user: action.user,
        is_varify: true,
      };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function UserProvider({ children }) {
  const [state, dispatch] = React.useReducer(userReducer, {
    isAuthenticated: false,
    token: "",
    user: null,
    is_varify: false,
  });

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}

function useUserState() {
  const context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error("useUserState must be used within a UserProvider");
  }
  return context;
}

function useUserDispatch() {
  const context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error("useUserDispatch must be used within a UserProvider");
  }
  return context;
}

export {
  UserProvider,
  useUserState,
  useUserDispatch,
  loginUser,
  signOut,
  qrCode,
  verifytoken,
};

// ###########################################################

const loginUser = (
  dispatch,
  login,
  password,
  history,
  setIsLoading,
  setIsError,
  setError,
) => {
  setIsError(false);
  setError([]);
  setIsLoading(true);

  const request = {
    url: env.BaseURL + "api/login",
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      user_name: login,
      password: password,
    },
  };
  axios(request)
    .then()
    .then((res) => {
      if (res.status === 200) {
        // setTimeout(() => {
        localStorage.setItem("token", res.data.jwt);
        localStorage.setItem("current_user", JSON.stringify(res.data.data));
        setIsError(false);
        setError([]);
        setIsLoading(false);
        dispatch({
          type: "LOGIN_SUCCESS",
          token: res.data.jwt,
          user: res.data.data,
        });
        history.push("/app/dashboard");
        // }, 2000);
      }
      if (res.status === 204) {
        setIsError(true);
        setError([
          {
            type: "field",
            value: "",
            msg: "User not found.",
            path: "user_name",
            location: "body",
          },
        ]);
        setIsLoading(false);
      }
      if (res.status === 203) {
        setIsError(true);
        setError([
          {
            msg: res.data.message,
            path: "password",
          },
        ]);
        setIsLoading(false);
      }
    })
    .catch((err) => {
      setIsError(true);
      if (err.response.data.status === 406) {
        setError(err.response.data.errors);
      } else {
        setError([
          {
            msg: "Something went Wrong.",
            path: "",
          },
        ]);
      }
      setIsLoading(false);
    });
};

function signOut(dispatch, history) {
  localStorage.removeItem("token");
  localStorage.removeItem("current_user");
  dispatch({ type: "SIGN_OUT_SUCCESS" });
  history.push("/login");
  window.location.reload();
}

function verifytoken(dispatch) {
  const token = localStorage.getItem("token");
  const user = JSON.parse(localStorage.getItem("current_user"));

  setTimeout(() => {
    const request = {
      url: env.BaseURL + "api/verify",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        token: token,
      },
    };
    if (token) {
      axios(request)
        .then()
        .then((res) => {
          if (res.status === 200) {
            localStorage.setItem("current_user", JSON.stringify(res.data.data));
            return dispatch({
              type: "VERYFY_TOKEN",
              is_login: true,
              token,
              user: res.data.data,
            });
          }
        })
        .catch((err) => {
          localStorage.removeItem("token");
          localStorage.removeItem("current_user");
          return dispatch({
            type: "VERYFY_TOKEN",
            is_login: false,
            token: "",
            user: null,
          });
        });
    } else {
      return dispatch({
        type: "VERYFY_TOKEN",
        is_login: false,
        token: "",
        user: null,
      });
    }
  }, 10);
}

function qrCode (dispatch, is_scan, qr_message='') {
    dispatch({ type: "QR_SCAN" , is_scan, qr_message});
}
