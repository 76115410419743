import React, { useState, useEffect } from "react";
import { Drawer, IconButton, List } from "@material-ui/core";
import {
  SupervisorAccount,
  ViewModule,
  Settings,
  Payment,
  Group,
  Home,
  ArrowBack,
  Message,
  Receipt,
  Contacts,
  Description,
} from "@material-ui/icons";
import { useTheme } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import useStyles from "./styles";
import SidebarLink from "./components/SidebarLink/SidebarLink";
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";

function Sidebar({ location, user }) {
  var classes = useStyles();
  var theme = useTheme();
  var { isSidebarOpened } = useLayoutState();
  var layoutDispatch = useLayoutDispatch();
  var [isPermanent, setPermanent] = useState(true);

  useEffect(function () {
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  });

  const structureAdmin = [
    { id: 0, label: "Dashboard", link: "/app/dashboard", icon: <Home /> },
    { id: 1, label: "Modules", link: "/app/modules", icon: <ViewModule /> },
    {
      id: 2,
      label: "Clients",
      link: "/app/users",
      icon: <SupervisorAccount />,
    },
  ];

  const structureUser = [
    { id: 0, label: "Dashboard", link: "/app/dashboard", icon: <Home /> },
    {
      id: 1,
      label: "Payment Reminder",
      icon: <Payment />,
      children: [
        {
          label: "Contacts",
          link: "/app/payment/contacts",
          icon: <Contacts />,
        },
        { label: "Template", link: "/app/payment/template", icon: <Message /> },
        { label: "Invoices", link: "/app/payment/invoice", icon: <Receipt /> },
      ],
    },
    {
      id: 2,
      label: "Group Messaging",
      icon: <Group />,
      children: [
        {
          label: "Contacts",
          link: "/app/groupmesaaging/contacts",
          icon: <Contacts />,
        },
        {
          label: "Template",
          link: "/app/groupmesaaging/templates",
          icon: <Message />,
        },
      ],
    },
    {
      id: 3,
      label: "API Documentation",
      link: "/app/apidocumentation",
      icon: <Description />,
    },
    { id: 4, label: "Settings", link: "/app/settings", icon: <Settings /> },
  ];

  return (
    <Drawer
      variant={isPermanent ? "permanent" : "temporary"}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={isSidebarOpened}
    >
      <div className={classes.toolbar} />
      <div className={classes.mobileBackButton}>
        <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
          <ArrowBack
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        </IconButton>
      </div>
      <List className={classes.sidebarList}>
        {user.is_admin != 1
          ? structureUser
              .filter((item) =>
                user.m_id.includes(item.id.toString()),
              )
              .concat(
                structureUser.filter((item) => item.id === 0 || (user.m_id.includes("1") && item.id === 4)),
              )
              .sort((a, b) => a.id - b.id)
              .map((link) => (
                <SidebarLink
                  key={link.id}
                  location={location}
                  isSidebarOpened={isSidebarOpened}
                  {...link}
                />
              ))
          : structureAdmin.map((link) => (
              <SidebarLink
                key={link.id}
                location={location}
                isSidebarOpened={isSidebarOpened}
                {...link}
              />
            ))}
      </List>
    </Drawer>
  );

  // ##################################################################

  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }
}

export default withRouter(Sidebar);
