import {
  FormControl,
  TextField,
  Grid,
  Card,
  Autocomplete,
  Divider,
} from "@mui/material";
import axios from "axios";
import { useEffect, useRef } from "react";
import { useState } from "react";
import env from "../../../../env.json";
import { useUserState } from "../../../../context/UserContext";
import { Button } from "@material-ui/core";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import "./style.css";

export default function Add(props) {
  const { token } = useUserState();
  const { data, setOpen, setSnackbarMsg, setSnackbarSeverity } = props;
  const [templateId, setTemplateId] = useState(null);
  const [companyId, setCompanyId] = useState(data?.cpy_id);
  const [contacts, setContacts] = useState([]);
  const [contactId, setContactId] = useState([]);
  const [attachedFile, setAttachedFile] = useState(null);
  const [errorList, setErrorList] = useState([]);

  const currentDate = dayjs();
  const triggerDateRef = useRef();
  const frequencyRef = useRef();
  const dueDateRef = useRef();
  const totalAmtRef = useRef();
  const invoiceDetailsRef = useRef();
  const fileLinkRef = useRef();
  const remarksRef = useRef();

  useEffect(() => {
    const request = {
      url: env.BaseURL + `api/user/payment/contacts?cpy_id=${companyId}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    if (companyId)
      axios(request)
        .then((res) => {
          if (res.data.status === 200) {
            setContacts(res.data.data);
            setContactId(res.data.data.filter((val) => (data?.contact_ids || "").includes(val.cont_id)).map(item=>item.cont_id))
          }
        })
        .catch((err) => {
          console.log(err);
        });
  }, [companyId]);

  const addInvoiceFunc = () => {
    const dataForm = new FormData();
    dataForm.append(
      "initial_date",
      triggerDateRef.current?.value &&
        dayjs(triggerDateRef.current?.value, "DD/MM/YYYY").format("YYYY-MM-DD"),
    );
    dataForm.append(
      "due_date",
      dueDateRef.current?.value &&
        dayjs(dueDateRef.current?.value, "DD/MM/YYYY").format("YYYY-MM-DD"),
    );
    dataForm.append("temp_id", templateId);
    dataForm.append("cpy_id", companyId || "");
    dataForm.append("cont_id", contactId.join());
    dataForm.append("send_days", frequencyRef.current?.value);
    dataForm.append("invoice_details", invoiceDetailsRef.current?.value);
    dataForm.append("total_amount", totalAmtRef.current?.value);
    dataForm.append("file_link", fileLinkRef.current?.value);
    dataForm.append("remarks", remarksRef.current?.value);
    dataForm.append("file", attachedFile);
    const request = {
      url: env.BaseURL + `api/user/payment/invoices?offset=${props.offset}`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: dataForm,
    };
    axios(request)
      .then((res) => {
        if (res.data.status === 200) {
          setOpen(false);
          setSnackbarSeverity("success")
          setSnackbarMsg("Invoice saved successfully");
          props.setOpenSnackbar(true);
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.status === 406) {
          setErrorList(err.response.data.errors);
        } else {
          setErrorList([
            {
              msg: "Something went Wrong.",
              path: "",
            },
          ]);
        }
      });
  };

  const updateInvoiceFunc = () => {
    const dataForm = new FormData();
    dataForm.append("id", data.id);
    dataForm.append(
      "initial_date",
      triggerDateRef.current?.value &&
        dayjs(triggerDateRef.current?.value, "DD/MM/YYYY").format("YYYY-MM-DD"),
    );
    dataForm.append(
      "due_date",
      dueDateRef.current?.value &&
        dayjs(dueDateRef.current?.value, "DD/MM/YYYY").format("YYYY-MM-DD"),
    );
    dataForm.append("temp_id", templateId);
    dataForm.append("cpy_id", companyId || "");
    dataForm.append("cont_id", contactId.join());
    dataForm.append("send_days", frequencyRef.current?.value);
    dataForm.append("invoice_details", invoiceDetailsRef.current?.value);
    dataForm.append("total_amount", totalAmtRef.current?.value);
    dataForm.append("file_link", fileLinkRef.current?.value);
    dataForm.append("remarks", remarksRef.current?.value);
    dataForm.append("file", attachedFile);
    const request = {
      url: env.BaseURL + `api/user/payment/invoices?offset=${props.offset}`,
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: dataForm,
    };
    axios(request)
      .then((res) => {
        if (res.data.status === 200) {
          setOpen(false);
          setSnackbarSeverity("success")
          setSnackbarMsg("Invoice updated successfully");
          props.setOpenSnackbar(true);
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.status === 406) {
          setErrorList(err.response.data.errors);
        } else {
          setErrorList([
            {
              msg: "Something went Wrong.",
              path: "",
            },
          ]);
        }
      });
  };

  function handleImageChange(e) {
    setAttachedFile(e.target.files[0]);
    fileLinkRef.current.value = e.target.files[0].name;
  }

  return (
    <>
      <Card style={{ padding: "20px", minWidth: "50vw", maxWidth: "80vh" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <Button onClick={() => setOpen(false)} style={{ color: "#ab0f0c" }}>
              Cancel
            </Button>
            {data?.id != null ? (
              <Button onClick={updateInvoiceFunc} style={{ color: "#008c53" }} >
                Update
              </Button>
            ) : (
              <Button onClick={addInvoiceFunc} style={{ color: "#008c53" }} >
                Add
              </Button>
            )}
          </div>
        </div>
        <Grid container spacing={2} mb={1}>
          <Grid item xs={12} sm={6} md={4}>
            <FormControl fullWidth>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  format="DD/MM/YYYY"
                  minDate={currentDate}
                  slotProps={{ textField: { size: "small" } }}
                  label="Trigger Date"
                  defaultValue={
                    data?.initial_date ? dayjs(data?.initial_date) : null
                  }
                  inputRef={triggerDateRef}
                />
              </LocalizationProvider>
            </FormControl>
            <p className="errorMsg">
              {errorList.length > 0 &&
                errorList.map((err) => {
                  if (err.path === "initial_date")
                    return <small>{err.msg}!</small>;
                })}
            </p>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormControl fullWidth>
              <TextField
                size="small"
                required
                label="Frequency"
                placeholder="Enter Days"
                InputProps={{ inputProps: { min: "1" } }}
                inputRef={frequencyRef}
                type="number"
                defaultValue={data?.frequency}
              />
            </FormControl>
            <p className="errorMsg">
              {errorList.length > 0 &&
                errorList.map((err) => {
                  if (err.path === "send_days")
                    return <small>{err.msg}!</small>;
                })}
            </p>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormControl fullWidth>
              <Autocomplete
                id="tags-outlined"
                size="small"
                options={props.templates}
                getOptionLabel={(option) => option.template_name}
                filterSelectedOptions
                defaultValue={
                  data?.temp_id
                    ? props.templates.find(
                        (val) => val.temp_id === data.temp_id,
                      )
                    : null
                }
                onChange={(e, value) => {
                  setTemplateId(value?.temp_id);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Template"
                    placeholder="Select Template"
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormControl fullWidth>
              <Autocomplete
                id="tags-outlined"
                size="small"
                options={props.companies}
                getOptionLabel={(option) => option.cpy_name}
                filterSelectedOptions
                defaultValue={
                  data?.cpy_id
                    ? props.companies.find((val) => val.cpy_id === data.cpy_id)
                    : null
                }
                onChange={(e, value) => {
                  setCompanyId(value?.cpy_id);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    label="Company"
                    placeholder="Select Company"
                  />
                )}
              />
            </FormControl>
            <p className="errorMsg">
              {errorList.length > 0 &&
                errorList.map((err) => {
                  if (err.path === "cpy_id") return <small>{err.msg}!</small>;
                })}
            </p>
          </Grid>
          {contacts.length ?  <Grid item xs={12} sm={6} md={4}>
            <FormControl fullWidth>
              <Autocomplete
                multiple
                id="tags-outlined"
                size="small"
                options={contacts}
                getOptionLabel={(option) => option.name}
                filterSelectedOptions
                value={contacts.filter((val) => contactId.includes(val.cont_id)) }
                // value={contacts.filter((val) => (data?.contact_ids || "").includes(val.cont_id)) }
                onChange={(e, value) => {
                  setContactId(value.map((item) => item.cont_id));
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    label="Contacts"
                    placeholder="Select Contacts"
                  />
                )}
              />
            </FormControl>
            <p className="errorMsg">
              {companyId &&
                errorList.length > 0 &&
                errorList.map((err) => {
                  if (err.path === "cont_id") return <small>{err.msg}!</small>;
                })}
            </p>
          </Grid>:''}
        </Grid>
        <Divider textAlign="left">INVOICE DETAILS</Divider>
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} sm={6} md={4}>
            <FormControl fullWidth>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  format="DD/MM/YYYY"
                  slotProps={{ textField: { size: "small" } }}
                  defaultValue={data?.due_date ? dayjs(data?.due_date) : null}
                  inputRef={dueDateRef}
                  label="Due Date"
                />
              </LocalizationProvider>
            </FormControl>
            <p className="errorMsg">
              {errorList.length > 0 &&
                errorList.map((err) => {
                  if (err.path === "due_date") return <small>{err.msg}!</small>;
                })}
            </p>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormControl fullWidth>
              <TextField
                size="small"
                required
                label="Total Amount"
                placeholder="Enter Amount"
                inputRef={totalAmtRef}
                type="number"
                defaultValue={data?.total_amount || null}
              />
            </FormControl>
            <p className="errorMsg">
              {errorList.length > 0 &&
                errorList.map((err) => {
                  if (err.path === "total_amount")
                    return <small>{err.msg}!</small>;
                })}
            </p>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormControl fullWidth>
              <TextField
                size="small"
                label="Invoice Details"
                placeholder="Enter Details"
                inputRef={invoiceDetailsRef}
                defaultValue={data?.invoice_details || null}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <TextField
                size="small"
                label="Remarks"
                placeholder="Enter Remarks"
                inputRef={remarksRef}
                defaultValue={data?.remarks || null}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={8}>
            <FormControl
              className="invoiceFileLink"
              fullWidth
              style={{ position: "relative" }}
            >
              <TextField
                size="small"
                label="File Link"
                placeholder="Enter Path or Browse Files"
                defaultValue={data?.file_link || data?.attach_file || null}
                inputRef={fileLinkRef}
              />
              <label htmlFor="imageInput" style={{ display: "inline-block" }}>
                <span
                  style={{
                    fontSize: "20px",
                    fontWeight: "500",
                    color: "#000",
                    position: "absolute",
                    right: "10px",
                    top: "5px",
                    cursor: "pointer",
                  }}
                >
                  +
                </span>

                <input
                  type="file"
                  onChange={handleImageChange}
                  style={{ display: "none" }}
                  id="imageInput"
                />
              </label>
            </FormControl>
          </Grid>
        </Grid>
      </Card>
    </>
  );
}
