import React, { useState } from "react";
import {
  Grid,
  Button,
  IconButton,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
} from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import useStyles from "./Styles";
import PageTitle from "../../../components/PageTitle/PageTitle";
import { Check, Close, Create, Delete, Save } from "@material-ui/icons";
import { useEffect } from "react";
import env from "../../../env.json";
import axios from "axios";
import { useUserState } from "../../../context/UserContext";

export default function Modules(props) {
  var classes = useStyles();
  const { token } = useUserState();
  const [getModuleList, setGetModuleList] = useState([]);
  const [editingRowMid, setEditingRowMid] = useState(null);
  const [deleteRowMid, setDeleteRowMid] = useState(null);
  const [newValue, setNewValue] = useState(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openAddModuleDialog, setOpenAddModuleDialog] = useState(false);
  const [newModuleName, setNewModuleName] = useState(null);
  const [newModuleIcon, setNewModuleIcon] = useState(null);
  const [newImgIcon, setNewImgIcon] = useState(null);
  const [showError, setShowError] = useState(false);
  const [savingData, setSavingData] = useState(false);

  useEffect(() => {
    getModuleListFunc();
  }, []);

  const getModuleListFunc = () => {
    const requestModuleList = {
      url: env.BaseURL + `api/admin/allmodules`,
      method: "Get",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    axios(requestModuleList)
      .then((response) => {
        if (response.data.status === 200) {
          setGetModuleList(response.data.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleSave = (rowId) => {
    setSavingData(true);
    const data = new FormData();
    data.append("file", newModuleIcon);
    data.append("name", newValue);
    data.append("m_id", rowId);

    const requestEditModule = {
      url: env.BaseURL + `api/admin/editmodule`,
      method: "Post",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };
    axios(requestEditModule)
      .then((response) => {
        if (response.data.status === 200) {
          setSavingData(false);
          setEditingRowMid(null);
          setNewValue(null);
          getModuleListFunc();
          setNewModuleIcon(null);
          setNewImgIcon(null);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleCancel = () => {
    setEditingRowMid(null);
    setNewValue(null);
    getModuleListFunc();
  };

  const handleDelete = (rowId) => {
    const requestDeleteModule = {
      url: env.BaseURL + `api/admin/deletemodule`,
      method: "Post",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: {
        m_id: rowId,
      },
    };
    axios(requestDeleteModule)
      .then((response) => {
        if (response.data.status === 200) {
          setDeleteRowMid(null);
          setNewValue(null);
          setOpenConfirmDialog(false);
          getModuleListFunc();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleAdd = () => {
    const data = new FormData();
    data.append("file", newModuleIcon);
    data.append("name", newModuleName);

    const requestAddModule = {
      url: env.BaseURL + "api/admin/addmodule",
      method: "post",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios(requestAddModule)
      .then((response) => {
        if (response.data.status === 200) {
          setNewModuleIcon(null);
          setNewImgIcon(null);
          setNewModuleName(null);
          setOpenAddModuleDialog(false);
          getModuleListFunc();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const changeRowData = (rowId, newValue) => {
    const updatedModuleList = getModuleList.map((item) =>
      item.m_id === rowId ? { ...item, name: newValue } : item,
    );
    setNewValue(newValue);
    setGetModuleList(updatedModuleList);
  };

  const columns = [
    {
      name: "name",
      label: <b>Name</b>,
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({
          style: {
            width: "50%",
          },
        }),
        customBodyRender: (value, tableMeta) => {
          const rowName = tableMeta.rowData[0];
          const rowId = getModuleList.find((val) => val.name === rowName).m_id;
          return editingRowMid === rowId ? (
            <input
              className={classes.inputField}
              type="text"
              value={value}
              onChange={(e) => changeRowData(rowId, e.target.value)}
            />
          ) : (
            value
          );
        },
      },
    },
    {
      name: "icon",
      label: <b>Icon</b>,
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({
          style: {
            width: "25%",
          },
        }),
        customBodyRender: (value, tableMeta) => {
          const rowName = tableMeta.rowData[0];
          const rowId = getModuleList.find((val) => val.name === rowName).m_id;
          return editingRowMid === rowId ? (
            <>
              <label htmlFor="imageInput" style={{ display: "inline-block" }}>
                <div className={classes.imageField}>
                  {newModuleIcon ? (
                    <img
                      src={newImgIcon}
                      alt="Selected"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        borderRadius: "5px",
                      }}
                    />
                  ) : (
                    <img
                      src={env.BaseURL + value}
                      alt="Icon"
                      style={{
                        width: "60px",
                        height: "40px",
                        borderRadius: "5px",
                      }}
                    />
                  )}
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleImageChange}
                    style={{ display: "none" }}
                    id="imageInput"
                  />
                </div>
              </label>
            </>
          ) : (
            <img
              src={env.BaseURL + value}
              alt="Icon"
              style={{ width: "60px", height: "40px", borderRadius: "5px" }}
            />
          );
        },
      },
    },
    {
      label: <b>Action</b>,
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({
          style: {
            width: "25%",
          },
        }),
        customBodyRender: (value, tableMeta) => {
          const rowName = tableMeta.rowData[0];
          const rowId = getModuleList.find((val) => val.name === rowName).m_id;
          return (
            <>
              {!savingData ? (
                <>
                  {editingRowMid === rowId ? (
                    <>
                      <IconButton
                        style={{ margin: "0 10px 0 0", padding: 0 }}
                        aria-haspopup="true"
                        color="inherit"
                        onClick={() => handleSave(rowId)}
                      >
                        <Check
                          style={{ color: "#008c53", fontWeight: "600" }}
                        />
                      </IconButton>

                      <IconButton
                        style={{ margin: "0 10px 0 0", padding: 0 }}
                        aria-haspopup="true"
                        color="inherit"
                        onClick={() => handleCancel()}
                      >
                        <Close
                          style={{ color: "#ab0f0c", fontWeight: "600" }}
                        />
                      </IconButton>
                    </>
                  ) : (
                    <IconButton
                      style={{ margin: "0 10px 0 0", padding: 0 }}
                      aria-haspopup="true"
                      color="inherit"
                      onClick={() => {
                        setNewValue(rowName);
                        setEditingRowMid(
                          getModuleList.find((val) => val.name === rowName)
                            .m_id,
                        );
                      }}
                    >
                      <Create style={{ margin: 0, padding: 0 }} />
                    </IconButton>
                  )}
                </>
              ) : (
                <IconButton
                  style={{ margin: "0 10px 0 0", padding: 0 }}
                  aria-haspopup="true"
                  color="inherit"
                >
                  <CircularProgress style={{ width: "20px", height: "20px" }} />
                </IconButton>
              )}
              <IconButton
                style={{ margin: 0, padding: 0 }}
                aria-haspopup="true"
                color="inherit"
                aria-controls="delete-row"
                onClick={() => {
                  setDeleteRowMid(
                    getModuleList.find((val) => val.name === rowName).m_id,
                  );
                  setOpenConfirmDialog(true);
                }}
              >
                <Delete style={{ margin: 0, padding: 0 }} />
              </IconButton>
            </>
          );
        },
      },
    },
  ];

  const options = {
    selectableRowsOnClick: false,
    selectableRowsHideCheckboxes: true,
    setTableProps: () => {
      return {
        size: "small",
      };
    },
    pagination: false,
    search: false,
    print: false,
    download: false,
    filter: false,
    viewColumns: false,
  };

  const handleImageChange = (event) => {
    setNewModuleIcon(event.target.files[0]);
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setNewImgIcon(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  return (
    <>
      <PageTitle
        title="Modules"
        button={
          <Button
            onClick={() => setOpenAddModuleDialog(true)}
            variant="contained"
            size="small"
            color="primary"
          >
            Create Module
          </Button>
        }
      />
      <Grid container mt={2} className={classes.moduleContainer}>
        <Grid item xs={12}>
          <MUIDataTable
            title="Module List"
            data={getModuleList.map((value) => [value.name, value.icon])}
            columns={columns}
            options={options}
          />
        </Grid>
      </Grid>
      <Dialog
        open={openAddModuleDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <small
            style={{ display: !showError ? "none" : "block", color: "red" }}
          >
            {newModuleName != null ? "Icon is Required!" : "Name is Required!"}
          </small>
          <h3>Name</h3>
          <input
            className={classes.inputField}
            type="text"
            value={newModuleName}
            onChange={(e) => setNewModuleName(e.target.value)}
          />
          <h3>Icon</h3>
          <label htmlFor="imageInput" style={{ display: "inline-block" }}>
            <div className={classes.imageField}>
              {newModuleIcon ? (
                <img
                  src={newImgIcon}
                  alt="Selected"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    borderRadius: "6px",
                  }}
                />
              ) : (
                <span
                  style={{
                    fontSize: "36px",
                    fontWeight: "500",
                    color: "#c7c6c6",
                  }}
                >
                  +
                </span>
              )}
              <input
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                style={{ display: "none" }}
                id="imageInput"
              />
            </div>
          </label>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setNewModuleIcon(null);
              setNewModuleName(null);
              setOpenAddModuleDialog(false);
              setNewImgIcon(null);
            }}
            autoFocus
            style={{ color: "#ab0f0c" }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              if (newModuleIcon != null && newModuleName != null) {
                handleAdd();
              } else {
                setShowError(true);
              }
            }}
            style={{ color: "#008c53" }}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        onClose={() => {
          setOpenConfirmDialog(false);
          setDeleteRowMid(null);
        }}
        open={openConfirmDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure, you want to delete module ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenConfirmDialog(false);
              setDeleteRowMid(null);
            }}
            style={{ color: "#ab0f0c" }}
          >
            Cancel
          </Button>
          <Button
            style={{ color: "#008c53" }}
            onClick={() => handleDelete(deleteRowMid)}
            autoFocus
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
