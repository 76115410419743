import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { Create, Delete } from "@material-ui/icons";
import { useUserState } from "../../../context/UserContext";
import env from "../../../env.json";
import axios from "axios";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
} from "@material-ui/core";
import { DialogContentText } from "@mui/material";
import { Button } from "@mui/material";

export default function UserList({ setShowAddClient, setClientDetail }) {
  const [getClientList, setGetClientList] = useState([]);
  const [deleteRowCid, setDeleteRowCid] = useState(null);
  const [deleteRowUserid, setDeleteRowUserid] = useState(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const { token } = useUserState();

  useEffect(() => {
    getClientsFunc();
  }, []);

  const getClientsFunc = () => {
    const requestClients = {
      url: env.BaseURL + `api/admin/allclients`,
      method: "Get",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    axios(requestClients)
      .then((response) => {
        if (response.data.status === 200) {
          setGetClientList(response.data.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleDelete = (c_id, user_id) => {
    const requestDelClient = {
      url: env.BaseURL + `api/admin/deleteclient`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        c_id: c_id,
        user_id: user_id,
      },
    };
    axios(requestDelClient)
      .then((response) => {
        if (response.data.status === 200) {
          setDeleteRowCid(null);
          setDeleteRowUserid(null);
          getClientsFunc();
          setOpenConfirmDialog(false);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const columns = [
    {
      name: "client_name",
      label: <b>Name</b>,
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({
          style: {
            width: "20%",
          },
        }),
      },
    },
    {
      name: "phone",
      label: <b>Contact No.</b>,
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({
          style: {
            width: "15%",
          },
        }),
      },
    },
    {
      name: "email",
      label: <b>Email</b>,
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({
          style: {
            width: "30%",
          },
        }),
      },
    },
    {
      name: "modules",
      label: <b>Modules</b>,
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({
          style: {
            width: "15%",
          },
        }),
      },
    },
    {
      name: "api_access_token",
      label: <b>API Access token</b>,
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({
          style: {
            width: "30%",
          },
        }),
      },
    },
    {
      label: <b>Action</b>,
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({
          style: {
            width: "max-content",
            display: "flex",
          },
        }),
        customBodyRender: (value, tableMeta) => {
          const rowName = tableMeta.rowData[0];
          const rowDetail = getClientList.find(
            (val) => val.client_name === rowName,
          );
          return (
            <>
              <IconButton
                style={{ margin: "0 10px 0 0", padding: 0 }}
                aria-haspopup="true"
                color="inherit"
                onClick={() => {
                  setShowAddClient(true);
                  setClientDetail(rowDetail);
                }}
              >
                <Create style={{ margin: 0, padding: 0 }} />
              </IconButton>
              <IconButton
                style={{ margin: 0, padding: 0 }}
                aria-haspopup="true"
                color="inherit"
                aria-controls="delete-row"
                onClick={() => {
                  setDeleteRowCid(
                    getClientList.find((val) => val.client_name === rowName)
                      .c_id,
                  );
                  setDeleteRowUserid(
                    getClientList.find((val) => val.client_name === rowName)
                      .user_id,
                  );
                  setOpenConfirmDialog(true);
                }}
              >
                <Delete style={{ margin: 0, padding: 0 }} />
              </IconButton>
            </>
          );
        },
      },
    },
  ];

  const options = {
    selectableRowsOnClick: false,
    selectableRowsHideCheckboxes: true,
    setTableProps: () => {
      return {
        size: "small",
      };
    },
    pagination: false,
    search: false,
    print: false,
    download: false,
    filter: false,
    viewColumns: false,
  };

  return (
    <>
      <Grid container mt={2} style={{ padding: "15px 30px 30px", overflowY: "auto", maxHeight: "68vh" }}>
        <Grid item xs={12}>
          <MUIDataTable
            title="Client List"
            data={getClientList.map((value) => [
              value.client_name,
              value.phone,
              value.email,
              value.modules,
              value.api_access_token,
            ])}
            columns={columns}
            options={options}
          />
        </Grid>
      </Grid>
      <Dialog
        onClose={() => {
          setOpenConfirmDialog(false);
          setDeleteRowCid(null);
          setDeleteRowUserid(null);
        }}
        open={openConfirmDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure, you want to delete client ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenConfirmDialog(false);
              setDeleteRowCid(null);
              setDeleteRowUserid(null);
            }}
            style={{ color: "#ab0f0c" }}
          >
            Cancel
          </Button>
          <Button
            style={{ color: "#008c53" }}
            onClick={() => handleDelete(deleteRowCid, deleteRowUserid)}
            autoFocus
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
