import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  AppBar,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
  Snackbar,
} from "@material-ui/core";
import {
  Menu as MenuIcon,
  Close as ArrowBackIcon,
  Create,
  Settings,
} from "@material-ui/icons";
import classNames from "classnames";
import useStyles from "./styles";
import { Typography } from "../Wrappers";
import UserAvatar from "../UserAvatar";
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";
import { useUserDispatch, signOut, qrCode } from "../../context/UserContext";
import ChangePasswordModal from "./ChangePasswordModal";
import ProfileEditModal from "./ProfileEditModal";
import QrCodeModal from "./QrModal";
import env from "../../env.json";
import { Alert, CircularProgress } from "@mui/material";
import "./style.css";

export default function Header(props) {
  const classes = useStyles();
  const { user, token, message, setMessage, setIsQrScan } = props;
  const [open, setOpen] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [profileMenu, setProfileMenu] = useState(null);
  const [qrModal, setQrModal] = useState(false);
  const [qrImage, setQRImage] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [loggedin, setLoggedin] = useState(false);
  var layoutState = useLayoutState();
  var layoutDispatch = useLayoutDispatch();
  var userDispatch = useUserDispatch();

  const getQrScanner = async () => {
    const request = {
      url: env.BaseURL + `api/user/getqrcode`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    await axios(request)
      .then((res) => {
        if (res.data.status === 200) {
          setSpinner(false);
          setQRImage(res.data.qrcode);
          setMessage(res.data.message);
          if (res.data.qrcode != null && res.data.qrcode != "") {
            setQrModal(true);
            // getQrScanner();
          } else {
            setOpenSnackbar(true);
          }
          if (res.data.is_scan) {
            setIsQrScan(true);
            setLoggedin(true);
          } else {
            setIsQrScan(false);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getQrScanner();
  }, []);

  return (
    <>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            color="inherit"
            onClick={() => toggleSidebar(layoutDispatch)}
            className={classNames(
              classes.headerMenuButtonSandwich,
              classes.headerMenuButtonCollapse,
            )}
          >
            {layoutState.isSidebarOpened ? (
              <ArrowBackIcon
                classes={{
                  root: classNames(
                    classes.headerIcon,
                    classes.headerIconCollapse,
                  ),
                }}
              />
            ) : (
              <MenuIcon
                classes={{
                  root: classNames(
                    classes.headerIcon,
                    classes.headerIconCollapse,
                  ),
                }}
              />
            )}
          </IconButton>
          <Typography variant="h6" weight="medium" className={classes.logotype}>
            <img src="./logo.png" style={{height:'50px',filter:'drop-shadow(0 1mm 6mm rgb(255,255,255)',backgroundColor: '#fff',
borderRadius: '25px',
padding: '5px',
marginTop:'5px'}} alt="logo"/>
          </Typography>
          <div className={classes.grow} />
          {user.is_admin === 1 ? null : spinner ? (
            <>
              <CircularProgress sx={{ color: "white", mr: "5px" }} size={35} />
              <p>Please wait, it may take upto 5 minutes</p>
            </>
          ) : (
            <IconButton
              aria-haspopup="true"
              color="inherit"
              className={classes.headerMenuButton}
              aria-controls="profile-menu"
              onClick={() => {
                setSpinner(true);
                getQrScanner();
              }}
            >
              <img className={classes.imageIcon} src={"qr_code.svg"} />
            </IconButton>
          )}
          <IconButton
            aria-haspopup="true"
            color="inherit"
            className={classes.headerMenuButton}
            aria-controls="profile-menu"
            onClick={(e) => setProfileMenu(e.currentTarget)}
          >
            <UserAvatar name={user.username} bgcolor="rgb(189, 189, 189)" />
          </IconButton>

          <Menu
            id="profile-menu"
            open={Boolean(profileMenu)}
            anchorEl={profileMenu}
            onClose={() => setProfileMenu(null)}
            className={classes.headerMenu}
            classes={{ paper: classes.profileMenu }}
            disableAutoFocusItem
          >
            <div className={classes.profileMenuUser}>
              <Typography variant="h4" weight="medium">
                {user.username}
              </Typography>
            </div>
            {!user.is_admin && (
              <>
                {/* {" "}
                <MenuItem
                  className={classNames(
                    classes.profileMenuItem,
                    classes.headerMenuItem,
                  )}
                  onClick={() => setOpenEdit(true)}
                >
                  <Create className={classes.profileMenuIcon} /> Edit Profile
                </MenuItem> */}
                <MenuItem
                  className={classNames(
                    classes.profileMenuItem,
                    classes.headerMenuItem,
                  )}
                  onClick={() => setOpen(true)}
                >
                  <Settings className={classes.profileMenuIcon} /> Change
                  Password
                </MenuItem>
              </>
            )}
            <div className={classes.profileMenuUser}>
              <Typography
                className={classes.profileMenuLink}
                color="primary"
                onClick={() => signOut(userDispatch, props.history)}
              >
                Sign Out
              </Typography>
            </div>
          </Menu>
        </Toolbar>
        <ChangePasswordModal open={open} token={token} setOpen={setOpen} />
        {/* <ProfileEditModal
          user={user}
          open={openEdit}
          token={token}
          setOpen={setOpenEdit}
        /> */}
        <QrCodeModal
          qrImage={qrImage}
          message={message}
          open={qrModal}
          setOpen={setQrModal}
        />
      </AppBar>
      <Snackbar
        open={openSnackbar}
        className={loggedin ? "snackbarSuccess" : "snackbarError"}
        autoHideDuration={3000}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert
          onClose={() => setOpenSnackbar(false)}
          severity={loggedin ? "success" : "error"}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </>
  );
}
