import { Button, Grid, Pagination } from "@mui/material";
import { Search as SearchIcon } from "@material-ui/icons";
import { InputBase } from "@material-ui/core";
import PageTitle from "../../../../components/PageTitle/PageTitle";
import UserCard from "../../../../components/Card/UserCard";
import { useEffect, useRef, useState } from "react";
import env from "../../../../env.json";
import { useUserState } from "../../../../context/UserContext";
import DeleteModal from "../../../../components/DeleteModal/DeleteModal";
import axios from "axios";
import UserPopover from "../../../../components/Popover/UserPopover";
import "./style.css";
import ContactDetails from "./contactDetails";
import UserSnackbar from "../../../../components/Snackbar.js/UserSnackbar";

export default function Contacts() {
  const { token } = useUserState();
  const [timer, setTimer] = useState();
  const [isSearchOpen, setSearchOpen] = useState(false);
  const searchKeyRef = useRef();
  const [offset, setOffset] = useState(0);
  const [itemCount, setItemCount] = useState(0);
  const [getContactList, setGetContactList] = useState([]);
  const [getContactId, setGetContactId] = useState(null);
  const [getContactDetail, setGetContactDetail] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteDone, setDeleteDone] = useState(false);
  const [popoverEvent, setPopoverEvent] = useState(null);
  const [contactOpen, setContactOpen] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState(null);
  const [snackbarSeverity, setSnackbarSeverity] = useState(null);

  const open = Boolean(popoverEvent);
  const id = open ? "popover-textfield" : undefined;

  useEffect(() => {
    getContactListFunc();
    if (!contactOpen) {
      setPopoverEvent(null);
      setGetContactId(null);
    }
  }, [contactOpen, offset]);

  useEffect(() => {
    if (deleteDone) {
      setOpenDeleteModal(false);
      deleteContact(getContactId);
    }

    if (getContactId != null) {
      setGetContactDetail(
        getContactList.find((val) => val.cont_id === getContactId),
      );
    } else {
      setGetContactDetail(null);
    }
  }, [deleteDone, getContactId]);

  const handelSearching = async () => {
    const searchKey = searchKeyRef.current?.value;
    if (searchKey.length > 0) {
      clearTimeout(timer);
      let key = setTimeout(() => {
        const requestContacts = {
          url:
            env.BaseURL +
            `api/user/payment/contacts/searching?offset=${offset}&value=${searchKey}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        };
        axios(requestContacts)
          .then((response) => {
            setOffset(0);
            setSearchOpen(true);
            setItemCount(0);
            if (response.data.status === 200) {
              setGetContactList(response.data.data);
              setItemCount(parseInt(response.data.count));
            } else {
              setGetContactList([]);
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }, 500);
      setTimer(key);
    } else {
      setSearchOpen(false);
      setOffset(0);
      setTimeout(() => {
        getContactListFunc(true);
      }, 100);
    }
  };

  function getContactListFunc(zero = false) {
    const requestContacts = {
      url:
        !zero && isSearchOpen
          ? env.BaseURL +
            `api/user/payment/contacts/searching?offset=${offset}&value=${searchKeyRef.current?.value}`
          : env.BaseURL + `api/user/payment/contacts?offset=${offset}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    axios(requestContacts)
      .then((response) => {
        if (response.data.status === 200) {
          setItemCount(parseInt(response.data.count));
          setGetContactList(response.data.data);
        } else {
          setGetContactList([]);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function deleteContact(id) {
    const request = {
      url: env.BaseURL + `api/user/payment/contacts?offset=${offset}`,
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        cont_id: id,
      },
    };
    axios(request)
      .then((res) => {
        getContactListFunc();
        if (res.data.status === 200) {
          setDeleteDone(false);
          setGetContactId(null);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const contactform = (
    <ContactDetails
      data={getContactDetail}
      setContactOpen={setContactOpen}
      offset={offset}
      setSnackbarSeverity={setSnackbarSeverity}
      setSnackbarMsg={setSnackbarMsg}
      setOpenSnackbar={setOpenSnackbar}
    />
  );

  return (
    <>
      <PageTitle
        title="Contacts"
        searching={
          <div className="search">
            <SearchIcon className="searchIcon" />
            <InputBase
              inputRef={searchKeyRef}
              onChange={handelSearching}
              placeholder="Search…"
              className="searchInput"
            />
          </div>
        }
        button={
          <Button
            variant="contained"
            size="small"
            color="primary"
            aria-describedby={id}
            onClick={(event) => {
              setPopoverEvent(event.currentTarget);
              setContactOpen(true);
              setGetContactId(null);
            }}
          >
            Create Contact
          </Button>
        }
      />
      {getContactList.length > 0 && (
        <Pagination
          count={itemCount}
          onChange={(e, v) => setOffset((v - 1) * 12)}
          color="primary"
          style={{ float: "right" }}
        />
      )}
      <Grid
        container
        spacing={3}
        style={{ padding: "0 30px 30px", overflowY: "auto", height: "65vh" }}
      >
        {getContactList.length > 0 ? (
          getContactList.map(
            (value) =>
              value.company_name && (
                <Grid key={value.cont_id} item xs={12} sm={3.9} md={3}>
                  <UserCard
                    {...value}
                    id={id}
                    setOpenDeleteModal={setOpenDeleteModal}
                    setPopoverEvent={setPopoverEvent}
                    setContactOpen={setContactOpen}
                    setId={setGetContactId}
                  />
                </Grid>
              ),
          )
        ) : (
          <p style={{ marginLeft: "45%", marginTop: "20%" }}>No Data Found</p>
        )}
      </Grid>
      <UserPopover
        id={id}
        open={open}
        form={contactform}
        setOpen={setContactOpen}
        popoverEvent={popoverEvent}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      />
      <DeleteModal
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        setDone={setDeleteDone}
      />
      <UserSnackbar
        severity={snackbarSeverity}
        open={openSnackbar}
        setClose={setOpenSnackbar}
        message={snackbarMsg}
      />
    </>
  );
}
