import React from "react";
import { CardContent, CardHeader } from "@mui/material";
import { Typography } from "@mui/material";
import { Card } from "@mui/material";

export default function TemplatesList(props) {
  return (
    <div
      className="cardContainer"
      onClick={() => {
        props.setGetId(props.temp_id);
        props.openModal(true);
      }}
    >
      <Card
        sx={{ width: "100%", height: "300px", cursor: "pointer" }}
        className="fold-corner"
      >
        <CardHeader
          style={{ padding: "10px 7px 5px" }}
          title={props.template_name}
        />
        <CardContent style={{ padding: "8px 7px 10px" }}>
          <Typography
            sx={{
              mb: "5px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <small className="templateContent">{props.template_body}</small>
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
}
