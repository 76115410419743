import { useRef, useState } from "react";
import env from "../../../../env.json";
import { useUserState } from "../../../../context/UserContext";
import axios from "axios";
import { Button, FormControl, Grid, TextField } from "@mui/material";

export default function AddCompany(props) {
  const { token } = useUserState();
  const { setCompanyDeps } = props;
  const newCompanyFieldRef = useRef();
  const [errorList, setErrorList] = useState([]);

  function addCompany() {
    const request = {
      url: env.BaseURL + `api/user/payment/companies`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        cpy_name: newCompanyFieldRef.current?.value,
      },
    };
    axios(request)
      .then((res) => {
        if (res.data.status === 200) {
          setCompanyDeps(false);
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.status === 406) {
          setErrorList(err.response.data.errors);
        } else {
          setErrorList([
            {
              msg: "Something went Wrong.",
              path: "",
            },
          ]);
        }
      });
  }

  return (
    <>
      <div
        style={{
          padding: "15px 15px 20px",
          margin: "10px 15px",
          maxWidth: "280px",
        }}
      >
        <h3
          style={{
            color: "#4f4e4e",
            fontWeight: "500",
            margin: "0px 0px 15px",
          }}
        >
          Create New Company
        </h3>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <TextField
              size="small"
              label="Company Name"
              inputRef={newCompanyFieldRef}
              required
            />
          </FormControl>
          <p className="errorMsg">
            {errorList.length > 0 &&
              errorList.map((err) => {
                if (err.path === "cpy_name") return <small>{err.msg}!</small>;
              })}
          </p>
        </Grid>
        <Grid mt={2} mb={2} style={{ float: "right" }}>
          <Button
            style={{
              fontWeight: "600",
              marginRight: "20px",
              color: "#ab0f0c",
            }}
            size="small"
            onClick={() => {
              setCompanyDeps(false);
            }}
          >
            Cancel
          </Button>
          <Button
            size="small"
            style={{
              color: "#008c53",
              fontWeight: "600",
            }}
            onClick={addCompany}
          >
            Add
          </Button>
        </Grid>
      </div>
    </>
  );
}
