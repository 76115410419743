import { Card, Typography } from "@mui/material";
import PageTitle from "../../../components/PageTitle/PageTitle";

export default function Apidoc() {
  return (
    <>
      <PageTitle title="API Documentation" />
      <Card
        sx={{
          padding: "10px",
          margin: "10px",
          overflowY: "auto",
          height: "68vh",
        }}
      >
        <Typography sx={{ marginBottom: "10px", wordBreak: "break-all" }}>
          API URL :- https://api.app.quicktikk.com/api/user/v1/sendmessage <br />
          METHOD :- "POST"
          <br />
          BODY :- FORM-DATA
          <br />
          AUTHORIZATION :- Bearer {"{Token}"}
        </Typography>
        <table
          border="1"
          cellpadding="2"
          style={{
            borderCollapse: "collapse",
            width: "90%",
            backgroundColor: "white",
            margin: "auto",
          }}
        >
          <tbody>
            <tr>
              <th>Key</th>
              <th>Value(Format)</th>
              <th>Description</th>
            </tr>
            <tr>
              <td>
                <code>userid*</code>
              </td>
              <td>Text</td>
              <td>Username or User ID</td>
            </tr>
            <tr>
              <td>
                <code>password*</code>
              </td>
              <td>Text</td>
              <td>Password of user</td>
            </tr>
            <tr>
              <td>
                <code>receiverMobileNo or recipientIds*</code>
              </td>
              <td>Text or Array</td>
              <td>single contact or array of contacts followed by @c.us</td>
            </tr>
            <tr>
              <td>
                <code>receiverName*</code>
              </td>
              <td>Text</td>
              <td>The name of the receiver</td>
            </tr>
            <tr>
              <td>
                <code>message*</code>
              </td>
              <td>Text</td>
              <td>Message to be sent</td>
            </tr>
            <tr>
              <td>
                <code>filePathUrl</code>
              </td>
              <td>Text</td>
              <td>File path that will be sent along with message</td>
            </tr>
          </tbody>
        </table>

     <p>NOTE:- Keys with * are required</p>
     <Typography>Example Request</Typography>
     <pre style={{backgroundColor: "#001f3f",color:'white'}}>{JSON.stringify({
            userid: "user",
            password: "password",
            receiverMobileNo: "234456****",
            receiverName: "Admin",
            message: "Welcome to Quicktikk"
        }, null, 2)}</pre>
 
         <Typography sx={{paddingTop:'10px'}}>Example Response</Typography>
         <pre style={{backgroundColor: "#001f3f",color:'white'}}>{JSON.stringify({
            "status": 200,
            "message": "success",
            "result": {
                "id": "7249095909....",
                "groupId": "724909590918....",
                "creationTime": "2023-08-21T13:00:54.925+0000"
            }
        }, null, 2)}</pre>
     
      </Card>
    </>
  );
}
