import React from "react";
import { CardContent, IconButton, Link, Tooltip } from "@mui/material";
import { Typography } from "@mui/material";
import { Card } from "@mui/material";
import "./style.css";
import {
  ControlPoint,
  Create,
  Delete,
  InsertDriveFile,
  Loop,
  Person,
} from "@material-ui/icons";

export default function InvoiceCard(props) {
  return (
    <div className="shadow">
      <Card style={{ width: "100%", height: "200px" }} className="fold">
        <CardContent>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              sx={{
                mb: "5px",
                fontSize: "14px",
                textTransform: "uppercase",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              <Tooltip title={props.company}>{props.company}</Tooltip>
            </Typography>
            <Tooltip title="Frequency">
              <div style={{ display: "flex", height: "fit-content" }}>
                <Loop sx={{ fontSize: "20px !important", width: 10 }} />
                <span>{props.frequency}</span>
              </div>
            </Tooltip>
          </div>
          <Typography
            sx={{
              mb: "5px",
              display: "flex",
              alignItems: "center",
              fontSize: "13px",
              fontWeight: 100,
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            <Person style={{ height: "20px" }} />
            <Tooltip title={props.contact}> {props.contact}</Tooltip>
          </Typography>

          <Typography
            variant="h6"
            sx={{
              mb: "5px",
              display: "flex",
              alignItems: "center",
            }}
          >
            ₹{props.total_amount}
          </Typography>

          <Typography
            sx={{
              mb: "5px",
              fontSize: "13px",
              textTransform: "uppercase",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            DUE DATE: {props.due_date}
          </Typography>
          <Typography
            sx={{
              mb: "5px",
              fontSize: "13px",
              textTransform: "uppercase",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            START ON: {props.initial_date}
          </Typography>

          {props.isQrScan && (
            <>
              <Tooltip title="Edit">
                <IconButton
                  style={{ height: "20px", float: "right", padding: "0" }}
                  onClick={(event) => {
                    props.setIsEdit(false);
                    props.setInvoiceOpen(true);
                    props.setGetId(props.id);
                    props.openModal(event.currentTarget);
                  }}
                >
                  <Create style={{ height: "20px", color: "#536DFE" }} />
                </IconButton>
              </Tooltip>
              {props.total_amount > 0 && (
                <Tooltip title="Receive Amount">
                  <IconButton
                    style={{
                      height: "20px",
                      padding: "0",
                      float: "left",
                      padding: "0",
                    }}
                    onClick={(event) => {
                      props.setIsEdit(true);
                      props.setInvoiceOpen(true);
                      props.setGetId(props.id);
                      props.openModal(event.currentTarget);
                    }}
                  >
                    <ControlPoint
                      style={{ height: "20px", color: "#536DFE" }}
                    />
                  </IconButton>
                </Tooltip>
              )}
            </>
          )}
          {
          (props.file_link != null && props.file_link != "") ? (
            <Tooltip title="Open File">
              <Link
                href={props.file_link}
                target="blank"
                style={{ float: "right", padding: "0" }}
              >
                <InsertDriveFile style={{ height: "20px", color: "#536DFE" }} />
              </Link>
            </Tooltip>
          ) : null}
          <Tooltip title="Delete">
            <IconButton
              style={{ height: "20px", float: "right", padding: "0" }}
              onClick={() => {
                props.setGetId(props.id);
                props.setOpenDeleteModal(true);
              }}
            >
              <Delete style={{ height: "20px", color: "#536DFE" }} />
            </IconButton>
          </Tooltip>
        </CardContent>
      </Card>
    </div>
  );
}
