import PageTitle from "../../../../components/PageTitle/PageTitle";
import { useEffect, useState } from "react";
import TemplatesList from "./listing";
import { useUserState } from "../../../../context/UserContext";
import env from "../../../../env.json";
import axios from "axios";
import { Grid } from "@material-ui/core";
import DeleteModal from "../../../../components/DeleteModal/DeleteModal";
import Add from "./add";
import UserPopover from "../../../../components/Popover/UserPopover";
import { Button } from "@mui/material";
import TemplateModal from "./modal";
import "./style.css";
import UserSnackbar from "../../../../components/Snackbar.js/UserSnackbar";

export default function TemplateMessaging() {
  const { token } = useUserState();
  const [templateList, setTamplateList] = useState([]);
  const [getTamplateId, setGetTamplateId] = useState(null);
  const [deleteDone, setDeleteDone] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [popoverEvent, setPopoverEvent] = useState(null);
  const [popoverDeps, setPopoverDeps] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editTemplate, setEditTemplate] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState(null);
  const [snackbarSeverity, setSnackbarSeverity] = useState(null);

  const open = Boolean(popoverEvent);
  const id = open ? "popover-textfield" : undefined;

  useEffect(() => {
    getClientsFunc();

    if (!isEdit) {
      setEditTemplate(null);
    } else {
      setEditTemplate(templateList.find((val) => val.t_id === getTamplateId));
    }

    if (!popoverDeps) {
      setPopoverEvent(null);
      setIsEdit(false);
      getClientsFunc();
    }

    if (deleteDone) {
      const request = {
        url: env.BaseURL + `api/user/messaging/tamplate`,
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: {
          id: getTamplateId,
        },
      };
      axios(request)
        .then((res) => {
          setGetTamplateId(null);
          getClientsFunc();
          setDeleteDone(false);
          setOpenDeleteModal(false);
          setOpenModal(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [deleteDone, popoverDeps, isEdit, openModal]);

  const getClientsFunc = () => {
    const requestClients = {
      url: env.BaseURL + `api/user/messaging/tamplate`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    axios(requestClients)
      .then((response) => {
        if (response.data.status === 200) {
          setTamplateList(response.data.data);
        } else {
          setTamplateList([]);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const templateForm = (
    <Add
      data={editTemplate}
      setOpen={setPopoverDeps}
      setOpenSnackbar={setOpenSnackbar}
      setSnackbarMsg={setSnackbarMsg}
      setSnackbarSeverity={setSnackbarSeverity}
    />
  );

  return (
    <>
      <PageTitle
        title="Templates"
        button={
          <Button
            variant="contained"
            size="small"
            color="primary"
            aria-describedby={id}
            onClick={(event) => {
              event.stopPropagation();
              setPopoverEvent(event.currentTarget);
              setPopoverDeps(true);
            }}
          >
            Create Template
          </Button>
        }
      />
      <Grid
        container
        spacing={3}
        style={{ padding: "0 30px 30px", overflowY: "auto", height: "70vh" }}
      >
        {templateList.length > 0 ? (
          templateList.map((value) => (
            <Grid key={value.t_id} item xs={12} sm={4} md={3}>
              {value.temp_name && (
                <TemplatesList
                  temp_id={value.t_id}
                  template_name={value.temp_name}
                  template_body={value.temp_body}
                  setGetId={setGetTamplateId}
                  openModal={setOpenModal}
                />
              )}
            </Grid>
          ))
        ) : (
          <p style={{ marginLeft: "45%", marginTop: "20%" }}>No data found</p>
        )}
      </Grid>
      <DeleteModal
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        setDone={setDeleteDone}
      />
      <TemplateModal
        open={openModal}
        setOpen={setOpenModal}
        setOpenDeleteModal={setOpenDeleteModal}
        setPopoverEvent={setPopoverEvent}
        setPopoverDeps={setPopoverDeps}
        setIsEdit={setIsEdit}
        id={id}
        title={
          templateList.length > 0 && getTamplateId != null
            ? templateList.find((val) => val.t_id === getTamplateId).temp_name
            : null
        }
        data={
          templateList.length > 0 && getTamplateId != null
            ? templateList.find((val) => val.t_id === getTamplateId).temp_body
            : null
        }
      />
      <UserPopover
        id={id}
        open={open}
        form={templateForm}
        setOpen={setPopoverDeps}
        popoverEvent={popoverEvent}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      />
      <UserSnackbar
        severity={snackbarSeverity}
        open={openSnackbar}
        setClose={setOpenSnackbar}
        message={snackbarMsg}
      />
    </>
  );
}
