import {
  Avatar,
  Button,
  Card,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListSubheader,
  Tooltip,
  Skeleton,
  Stack,
  Typography,
  tooltipClasses,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  CircularProgress,
} from "@mui/material";
import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { useUserState } from "../../../context/UserContext";
import env from "../../../env.json";
import UserAvatar from "../../../components/UserAvatar/UserAvatar";
import dayjs from "dayjs";
import CsvDownloader from "react-csv-downloader";
import { Info, SaveAlt } from "@material-ui/icons";
import styled from "@emotion/styled";
import img from "./dash.png";
export default function UserDashboard() {
  const { token, user } = useUserState();
  const [loading, setLoading] = useState(true);
  const [loading_inv, setLoading_inv] = useState(true);
  const [loading_mess, setLoading_mess] = useState(false);
  const [groupList, setGroupList] = useState([]);
  const [msgLogList, setMsgLogList] = useState([]);
  const [contactList, setContactList] = useState([]);
  const [prContactList, setPrContactList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [waGrpList, setWaGrpList] = useState([]);
  const [invoiceLogList, setInvoiceLogList] = useState([]);
  const [upcomingMsgActivityList, setUpcomingMsgActivityList] = useState([]);
  const [showInvoiceLogs, setShowInvoiceLogs] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [
    upcomingInvoiceActivityList,
    setUpcomingInvoiceActivityList,
  ] = useState([]);

  useEffect(() => {
    get_logs(0);
    setShowInvoiceLogs(!user?.m_id.includes("2") ? true : false);
  }, []);

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#fff",
      color: "rgba(0, 0, 0, 0.87)",
      fontSize: "14px",
      maxWidth: "330px",
      padding: "8px",
      maxHeight: "55vh",
      overflowY: "auto",
      "&::-webkit-scrollbar": {
        display: "none",
      },
    },
  }));

  function get_logs(days) {
    setLoading(true);
    const log_request = {
      url: env.BaseURL + `api/user/dashboard/log?days=${days}`,
      method: "Get",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    axios(log_request)
      .then((response) => {
        if (response.data.status === 200) {
          setLoading(false);
          setMsgLogList(response.data.msg_log);
          setInvoiceLogList(response.data.invoice_log);
        }
      })
      .catch((err) => {
        setLoading(false);
      });

    const active_inv_request = {
      url: env.BaseURL + `api/user/dashboard/active-invoice`,
      method: "Get",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    axios(active_inv_request)
      .then((response) => {
        if (response.data.status === 200) {
          setLoading_inv(false);
          setUpcomingInvoiceActivityList(response.data.active_invoice);
        }
      })
      .catch((err) => {
        setLoading_inv(false);
      });

    const active_mess_request = {
      url: env.BaseURL + `api/user/dashboard/active-mess`,
      method: "Get",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    axios(active_mess_request)
      .then((response) => {
        if (response.data.status === 200) {
          setLoading_mess(false);
          const activeGrpMsg = response.data.active_group_mess.map(
            (activeGrpMsg) => ({
              ...activeGrpMsg,
              grp_msg: 1,
            }),
          );
          const activeMsg = response.data.active_mess.map((activeMsg) => ({
            ...activeMsg,
            grp_msg: 0,
          }));
          setUpcomingMsgActivityList([...activeMsg, ...activeGrpMsg]);
        }
      })
      .catch((err) => {
        setLoading_mess(false);
      });

    const requestGroup = {
      url: env.BaseURL + `api/user/messaging/contactgroups`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    axios(requestGroup)
      .then((response) => {
        if (response.data.status === 200) {
          setGroupList(response.data.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });

    const requestPrContact = {
      url: env.BaseURL + `api/user/payment/contacts`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    axios(requestPrContact)
      .then((response) => {
        if (response.data.status === 200) {
          setPrContactList(response.data.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });

    const requestContact = {
      url: env.BaseURL + `api/user/messaging/contacts`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    axios(requestContact)
      .then((response) => {
        if (response.data.status === 200) {
          setContactList(response.data.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });

    const requestCompanies = {
      url: env.BaseURL + `api/user/payment/companies`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    axios(requestCompanies)
      .then((response) => {
        if (response.data.status === 200) {
          setCompanyList(response.data.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });

    const requestWaGroup = {
      url: env.BaseURL + `api/user/messaging/whatsappgroups`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    axios(requestWaGroup)
      .then((response) => {
        if (response.data.status === 200) {
          setWaGrpList(response.data.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }
  const getAllData = async () => {
    setSpinner(true);
    const request = {
      url: env.BaseURL + `api/user/log?is_inv=${showInvoiceLogs ? 1 : 0}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    return await axios(request)
      .then((response) => {
        if (response.data.status === 200) {
          setSpinner(false);
          return response.data.log.map((item) => ({
            ...item,
            message: `\"${item.message}\"`,
          }));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const stopMessageActivityFunc = (id, grp_msg) => {
    const requestGroup = {
      url:
        env.BaseURL +
        `${
          grp_msg === 1
            ? `api/user/dashboard/stopGroupMessage`
            : `api/user/dashboard/stopMessage`
        }`,
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        id: id,
      },
    };
    axios(requestGroup)
      .then((response) => {
        if (response.data.status === 200) {
          get_logs(0);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const stopInvoiceActivityFunc = (id) => {
    const requestGroup = {
      url: env.BaseURL + `api/user/dashboard/stopPaymentReminder`,
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        id: id,
      },
    };
    axios(requestGroup)
      .then((response) => {
        if (response.data.status === 200) {
          get_logs(0);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <Grid
      container
      spacing={3}
      style={{
        padding: "30px",
        overflowY: "auto",
        minHeight: "10vh",
        maxHeight: "92vh",
      }}
    >
      <Grid item xs={12} sm={6}>
        <Card
          style={{ backgroundColor: "white", height: "78vh" }}
          elevation={5}
        >
          <List
            sx={{
              maxWidth: "100%",
              bgcolor: "background.paper",
              overflowY: "auto",
            }}
            subheader={
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                {user?.m_id.includes("2") && (
                  <ListSubheader
                    style={{
                      fontWeight: "500",
                      background: !showInvoiceLogs ? "#536DFE" : "none",
                    }}
                  >
                    <Button
                      style={{ color: !showInvoiceLogs ? "#fff" : "" }}
                      size="small"
                      onClick={() => setShowInvoiceLogs(false)}
                    >
                      Message Logs
                    </Button>
                  </ListSubheader>
                )}
                {user?.m_id.includes("1") && (
                  <ListSubheader
                    style={{
                      fontWeight: "500",
                      background: showInvoiceLogs ? "#536DFE" : "none",
                    }}
                  >
                    <Button
                      style={{ color: showInvoiceLogs ? "#fff" : "" }}
                      size="small"
                      onClick={() => setShowInvoiceLogs(true)}
                    >
                      Invoice Logs
                    </Button>
                  </ListSubheader>
                )}
                <ListSubheader sx={{ display: "flex", marginLeft: "auto" }}>
                  {spinner ? (
                    <CircularProgress
                      sx={{ color: "inherit", margin: "5px" }}
                      size={25}
                    />
                  ) : (
                    <CsvDownloader
                      filename={`AllLogs${dayjs(new Date()).format(
                        "DD-MM-YYYY",
                      )}`}
                      extension=".csv"
                      datas={getAllData}
                      text={
                        <Tooltip title="All Logs">
                          <SaveAlt />
                        </Tooltip>
                      }
                      style={{
                        border: 0,
                        fontSize: "15px",
                        color: "#1976d2",
                        cursor: "pointer",
                        fontWeight: "600",
                        padding: "4px 10px",
                        borderRadius: "4px",
                        background: "none",
                      }}
                    />
                  )}
                  <FormControl fullWidth sx={{ paddingTop: "3px" }}>
                    <Select
                      style={{ fontSize: "12px" }}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      defaultValue={0}
                      size="small"
                      onChange={(e) =>
                        e.target.value != 1 && get_logs(e.target.value)
                      }
                    >
                      <MenuItem value={0} style={{ fontSize: "12px" }}>
                        Today
                      </MenuItem>
                      <MenuItem value={7} style={{ fontSize: "12px" }}>
                        Last 7 Days
                      </MenuItem>
                      <MenuItem value={30} style={{ fontSize: "12px" }}>
                        Last 30 Days
                      </MenuItem>
                    </Select>
                  </FormControl>
                </ListSubheader>
              </div>
            }
          >
            {loading ? (
              <Stack spacing={1}>
                {Array.from(new Array(9)).map((item, index) => (
                  <div
                    key={index}
                    style={{ display: "flex", justifyContent: "space-around" }}
                  >
                    <Skeleton
                      variant="circular"
                      width={40}
                      height={40}
                    ></Skeleton>
                    <Skeleton variant="rectangular" width="80%" height={40} />
                  </div>
                ))}
              </Stack>
            ) : (
              <>
                {!showInvoiceLogs ? (
                  <div style={{ height: "70vh", overflowY: "auto" }}>
                    {msgLogList?.length > 0 ? (
                      msgLogList.map((item) => (
                        <>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              backgroundColor:
                                item.status === "success"
                                  ? "rgb(197 255 230)"
                                  : "#fac6c6",
                            }}
                          >
                            <ListItem
                              alignItems="flex-start"
                              style={{
                                paddingTop: "0",
                                paddingBottom: "0",
                              }}
                            >
                              <ListItemAvatar>
                                <Avatar
                                  style={{
                                    width: "30px",
                                    height: "30px",
                                    fontSize: "14px",
                                    fontWeight: "500",
                                  }}
                                  sx={{ bgcolor: "#536DFE" }}
                                >
                                  {item.api_call === 0
                                    ? "PM"
                                    : item.api_call === 1
                                    ? "WG"
                                    : "GM"}
                                </Avatar>
                              </ListItemAvatar>
                              <ListItemText
                                primary={
                                  <small style={{ fontSize: "14px" }}>
                                    {item.contact_name}
                                  </small>
                                }
                                secondary={
                                  <Fragment>
                                    <Typography
                                      sx={{ display: "inline" }}
                                      component="span"
                                      variant="body2"
                                      color="text.primary"
                                    >
                                      SENT ON :-
                                      {dayjs(item.sent_date).format(
                                        "DD-MMM-YYYY",
                                      ) +
                                        ", " +
                                        dayjs(
                                          item.sent_time,
                                          "HH:mm:ss",
                                        ).format("hh:mm A")}
                                    </Typography>
                                  </Fragment>
                                }
                              />
                            </ListItem>
                            <LightTooltip
                              className="shadow"
                              title={<small>{item.message}</small>}
                            >
                              <IconButton>
                                <Info
                                  style={{
                                    width: "18px",
                                    height: "18px",
                                    color: "#536DFE",
                                  }}
                                />
                              </IconButton>
                            </LightTooltip>
                          </div>
                          <Divider variant="inset" component="li" />
                        </>
                      ))
                    ) : (
                      <h4
                        style={{
                          color: "#7a7a7a",
                          width: "100%",
                          textAlign: "center",
                          fontWeight: "500",
                        }}
                      >
                        No Data Found!
                      </h4>
                    )}
                  </div>
                ) : (
                  <div style={{ height: "70vh", overflowY: "auto" }}>
                    {invoiceLogList?.length > 0 ? (
                      invoiceLogList.map((item) => (
                        <>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              backgroundColor:
                                item.status === "success"
                                  ? "rgb(197 255 230)"
                                  : "#fac6c6",
                            }}
                          >
                            <ListItem
                              alignItems="flex-start"
                              style={{
                                paddingTop: "0",
                                paddingBottom: "0",
                              }}
                            >
                              <ListItemAvatar>
                                <UserAvatar name={item.contact_name} />
                              </ListItemAvatar>
                              <ListItemText
                                primary={
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <small style={{ fontSize: "14px" }}>
                                      {item.contact_name} ({item.contact_no})
                                    </small>
                                    <small style={{ fontSize: "13px" }}>
                                      {item.module_name}
                                    </small>
                                  </div>
                                }
                                secondary={
                                  <Fragment>
                                    <Typography
                                      sx={{ display: "inline" }}
                                      component="span"
                                      variant="body2"
                                      color="text.primary"
                                    >
                                      SENT ON :-{" "}
                                      {dayjs(item.sent_date).format(
                                        "DD-MMM-YYYY",
                                      ) +
                                        ", " +
                                        dayjs(
                                          item.sent_time,
                                          "HH:mm:ss",
                                        ).format("hh:mm A")}
                                    </Typography>
                                  </Fragment>
                                }
                              />
                            </ListItem>
                            <LightTooltip
                              className="shadow"
                              title={<small>{item.message}</small>}
                            >
                              <IconButton>
                                <Info
                                  style={{
                                    width: "18px",
                                    height: "18px",
                                    color: "#536DFE",
                                  }}
                                />
                              </IconButton>
                            </LightTooltip>
                          </div>
                          <Divider variant="inset" component="li" />
                        </>
                      ))
                    ) : (
                      <h4
                        style={{
                          color: "#7a7a7a",
                          width: "100%",
                          textAlign: "center",
                          fontWeight: "500",
                        }}
                      >
                        No Data Found!
                      </h4>
                    )}
                  </div>
                )}
              </>
            )}
          </List>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6}>
        {user?.m_id.includes("2") && (
          <Grid item style={{ marginBottom: "4vh" }}>
            <Card
              style={{
                backgroundColor: "white",
              }}
              elevation={5}
            >
              <List
                sx={{
                  maxWidth: "98%",
                  bgcolor: "background.paper",
                  overflowY: "auto",
                }}
                subheader={
                  <ListSubheader style={{ fontWeight: "600" }}>
                    Upcoming Message Activities
                  </ListSubheader>
                }
              >
                {loading_mess ? (
                  <Stack spacing={1}>
                    {Array.from(new Array(4)).map((item, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                        }}
                      >
                        <Skeleton
                          variant="circular"
                          width={30}
                          height={30}
                        ></Skeleton>
                        <Skeleton
                          variant="rectangular"
                          width="80%"
                          height={40}
                        />
                      </div>
                    ))}
                  </Stack>
                ) : (
                  <div style={{ height: "28.18vh", overflowY: "auto" }}>
                    {upcomingMsgActivityList.length > 0 ? (
                      upcomingMsgActivityList.map((item) => (
                        <>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <ListItem
                              alignItems="flex-start"
                              style={{ paddingTop: "0", paddingBottom: "0" }}
                            >
                              <ListItemAvatar>
                                <Avatar
                                  style={{
                                    width: "30px",
                                    height: "30px",
                                    fontSize: "14px",
                                    fontWeight: "500",
                                  }}
                                  sx={{ bgcolor: "#536DFE" }}
                                >
                                  {item.grp_msg === 1
                                    ? "WG"
                                    : item.group_id != 0
                                    ? "GM"
                                    : "PM"}
                                </Avatar>
                              </ListItemAvatar>
                              <ListItemText
                                primary={
                                  <small style={{ fontSize: "14px" }}>
                                    {item.grp_msg === 1
                                      ? item.grp_id
                                          .split(",")
                                          .map(
                                            (item) =>
                                              waGrpList.find(
                                                (val) => val.id == item,
                                              )?.name,
                                          )
                                          .join(", ")
                                      : item.group_id === 0
                                      ? item.cont_ids
                                          .split(",")
                                          .map(
                                            (item) =>
                                              contactList.find(
                                                (val) => item == val.c_id,
                                              )?.name,
                                          )
                                          .join(", ")
                                      : groupList.find(
                                          (val) => item.group_id === val.g_id,
                                        )?.group_name}
                                  </small>
                                }
                                secondary={
                                  <Fragment>
                                    <Typography
                                      sx={{ display: "inline" }}
                                      component="span"
                                      variant="body2"
                                      color="text.primary"
                                    >
                                      SEND ON :-
                                      {item.grp_msg === 1
                                        ? dayjs(item.cron_run_date).format(
                                            "DD MMM YYYY",
                                          ) +
                                          ", " +
                                          dayjs(
                                            item.cron_run_time,
                                            "HH:mm",
                                          ).format("hh:mm A")
                                        : dayjs(
                                            item.custom_sent_date_time,
                                          ).format("DD MMM YYYY, hh:mm A")}
                                    </Typography>
                                  </Fragment>
                                }
                              />
                            </ListItem>
                            <LightTooltip
                              className="shadow"
                              title={<small>{item.msg}</small>}
                            >
                              <IconButton>
                                <Info
                                  style={{
                                    width: "18px",
                                    height: "18px",
                                    color: "#536DFE",
                                  }}
                                />
                              </IconButton>
                            </LightTooltip>
                            <Button
                              style={{
                                marginLeft: "5px",
                                minWidth: "45px",
                                padding: "2px 5px",
                                background: "#536DFE",
                              }}
                              size="small"
                              variant="contained"
                              onClick={() =>
                                stopMessageActivityFunc(item.id, item.grp_msg)
                              }
                            >
                              Stop
                            </Button>
                          </div>
                          <Divider variant="inset" component="li" />
                        </>
                      ))
                    ) : (
                      <h4
                        style={{
                          color: "#7a7a7a",
                          width: "100%",
                          textAlign: "center",
                          fontWeight: "500",
                        }}
                      >
                        No Data Found!
                      </h4>
                    )}
                  </div>
                )}
              </List>
            </Card>
          </Grid>
        )}
        {user?.m_id.includes("1") && (
          <Grid item>
            <Card style={{ backgroundColor: "white" }} elevation={5}>
              <List
                sx={{
                  maxWidth: "98%",
                  bgcolor: "background.paper",
                  overflowY: "auto",
                }}
                subheader={
                  <ListSubheader style={{ fontWeight: "600" }}>
                    Upcoming Invoice Activities
                  </ListSubheader>
                }
              >
                {loading_inv ? (
                  <Stack spacing={1}>
                    {Array.from(new Array(4)).map((item, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                        }}
                      >
                        <Skeleton
                          variant="circular"
                          width={30}
                          height={30}
                        ></Skeleton>
                        <Skeleton
                          variant="rectangular"
                          width="80%"
                          height={40}
                        />
                      </div>
                    ))}
                  </Stack>
                ) : (
                  <div style={{ height: "28.18vh", overflowY: "auto" }}>
                    {upcomingInvoiceActivityList?.length > 0 ? (
                      upcomingInvoiceActivityList.map((item) => (
                        <>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <ListItem
                              alignItems="flex-start"
                              style={{ paddingTop: "0", paddingBottom: "0" }}
                            >
                              <ListItemAvatar>
                                <UserAvatar
                                  name={
                                    companyList.find(
                                      (val) => val.cpy_id === item.cpy_id,
                                    )?.cpy_name
                                  }
                                />
                              </ListItemAvatar>
                              <ListItemText
                                primary={
                                  <>
                                    <small style={{ fontSize: "14px" }}>
                                      <div>
                                        <span>Company Name: &nbsp;</span>
                                        {
                                          companyList.find(
                                            (val) => val.cpy_id === item.cpy_id,
                                          )?.cpy_name
                                        }
                                      </div>
                                      <div>
                                        <span>Contact: &nbsp;</span>
                                        {item.cont_id
                                          .split(",")
                                          .map(
                                            (item) =>
                                              prContactList.find(
                                                (val) => item == val.cont_id,
                                              )?.name,
                                          )
                                          .join(", ")}
                                      </div>
                                    </small>
                                  </>
                                }
                                secondary={
                                  <Fragment>
                                    <Typography
                                      sx={{ display: "inline" }}
                                      component="span"
                                      variant="body2"
                                      color="text.primary"
                                    >
                                      SEND ON :-{" "}
                                      {item.cron_run_date &&
                                        dayjs(item.cron_run_date).format(
                                          "DD MMM YYYY",
                                        ) +
                                          ", " +
                                          dayjs(
                                            item.scheduler_time,
                                            "HH",
                                          ).format("hh:mm A")}
                                    </Typography>
                                  </Fragment>
                                }
                              />
                            </ListItem>
                            {item.attach_file != null && (
                              <LightTooltip
                                className="shadow"
                                title={<small>{item.attach_file}</small>}
                              >
                                <IconButton>
                                  <Info
                                    style={{
                                      width: "18px",
                                      height: "18px",
                                      color: "#536DFE",
                                    }}
                                  />
                                </IconButton>
                              </LightTooltip>
                            )}
                            <Button
                              style={{
                                marginLeft: "5px",
                                minWidth: "45px",
                                padding: "2px 5px",
                                background: "#536DFE",
                              }}
                              size="small"
                              variant="contained"
                              onClick={() => stopInvoiceActivityFunc(item.id)}
                            >
                              Stop
                            </Button>
                          </div>
                          <Divider variant="inset" component="li" />
                        </>
                      ))
                    ) : (
                      <h4
                        style={{
                          color: "#7a7a7a",
                          width: "100%",
                          textAlign: "center",
                          fontWeight: "500",
                        }}
                      >
                        No Data Found!
                      </h4>
                    )}
                  </div>
                )}
              </List>
            </Card>
          </Grid>
        )}
        {(!user?.m_id.includes("1") || !user?.m_id.includes("2")) && (
          <Grid
            item
            sx={{ display: "flex", justifyContent: "center", marginTop: "4vh" }}
          >
            <img src={img} />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
