import { useState } from "react";
import axios from "axios";
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import env from "../../env.json";
import { signOut, useUserDispatch } from "../../context/UserContext";

export default function ChangePasswordModal(props) {
  const { open, setOpen, token } = props;
  const userDispatch = useUserDispatch();
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState("");
  const [password, setPassword] = useState("");
  const [matchPassword, setMatchPassword] = useState("");

  const handleClose = () => setOpen(false);

  const handleSubmit = () => {
    setShowError(false);
    setError("");

    const request = {
      url: env.BaseURL + `api/user/change-password`,
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: {
        password: password,
      },
    };
    axios(request)
      .then((response) => {
        if (response.data.status === 200) {
          signOut(userDispatch);
        }
      })
      .catch((error) => {
        setShowError(true);
        setError("Something went wrong.");
        console.error(error);
      });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      style={{
        background: "#a7b5aa",
      }}
    >
      <DialogContent>
        <small style={{ display: !showError ? "none" : "block", color: "red" }}>
          {error}
        </small>
        <h3>New Password</h3>
        <input
          style={{
            borderRadius: "5px",
            border: "1px solid #c7c6c6",
            background: "#f3f0f0",
            padding: "8px 10px",
            width: "250px",
          }}
          type="text"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <h3>Repeat Password</h3>
        <input
          style={{
            borderRadius: "5px",
            border: "1px solid #c7c6c6",
            background: "#f3f0f0",
            padding: "8px 10px",
            width: "250px",
          }}
          type="text"
          value={matchPassword}
          onChange={(e) => setMatchPassword(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} autoFocus>
          Cancel
        </Button>
        <Button
          onClick={() => {
            if (password !== "" && password === matchPassword) {
              handleSubmit();
            } else {
              setShowError(true);
              setError(
                password === ""
                  ? "Password is Required!"
                  : "Password is not match!",
              );
            }
          }}
          autoFocus
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}
