import { Delete } from "@material-ui/icons";
import { IconButton } from "@mui/material";
import axios from "axios";
import MUIDataTable from "mui-datatables";
import env from "../../../../env.json";
import { useUserState } from "../../../../context/UserContext";

export default function ToolTipTable(props) {
  const {
    viewGroupContactList,
    showInfo,
    groupId,
    setViewGroupContactList,
  } = props;
  const { token } = useUserState();

  function deleteContact(id) {
    const request = {
      url: env.BaseURL + `api/user/messaging/contactgroupmapping`,
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        map_id: id,
        g_id: groupId,
      },
    };
    axios(request)
      .then((res) => {
        if (res.data.status === 200) {
          setViewGroupContactList(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const columns = [
    {
      label: <b>Name</b>,
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({
          style: {
            width: "40%",
          },
        }),
      },
    },
    {
      label: <b>Mobile</b>,
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({
          style: {
            width: "45%",
          },
        }),
      },
    },
    {
      label: !showInfo && <b>Action</b>,
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({
          style: {
            width: !showInfo ? "15%" : "0",
          },
        }),
        customBodyRender: (value, tableMeta) => {
          const rowId = viewGroupContactList?.find(
            (val) => val.mobile === tableMeta.rowData[1],
          ).map_id;
          return (
            !showInfo && (
              <>
                <IconButton
                  style={{ margin: 0, padding: 0 }}
                  aria-haspopup="true"
                  color="inherit"
                  aria-controls="delete-row"
                  onClick={() => deleteContact(rowId)}
                >
                  <Delete style={{ margin: 0, padding: 0 }} />
                </IconButton>
              </>
            )
          );
        },
      },
    },
  ];
  const options = {
    selectableRowsOnClick: false,
    selectableRowsHideCheckboxes: true,
    setTableProps: () => {
      return {
        size: "small",
      };
    },
    pagination: false,
    search: false,
    print: false,
    download: false,
    filter: false,
    viewColumns: false,
  };

  return (
    <div className="toolTipTable">
      <MUIDataTable
        title="Group Contact List"
        data={viewGroupContactList.map((value) => [value.name, value.mobile])}
        columns={columns}
        options={options}
      />
    </div>
  );
}
