import React from "react";
import { CardContent, Tooltip } from "@mui/material";
import { Typography } from "@mui/material";
import { Card } from "@mui/material";
import UserAvatar from "../UserAvatar";
import { Create, Delete, Email, LocalPhone, Person } from "@material-ui/icons";
import "./style.css";
import { IconButton } from "@material-ui/core";

export default function UserCard(props) {
  return (
    <div className="cardContainer">
      <Card sx={{ width: "100%" }}>
        <div className="ribbon-2">
          <Tooltip title="Edit">
            <IconButton
              style={{ margin: "0 5px 0 0", padding: 0 }}
              aria-haspopup="true"
              color="inherit"
              aria-describedby={props.id}
              onClick={(event) => {
                props.setContactOpen(true);
                props.setId(props.cont_id);
                props.setPopoverEvent(event.currentTarget);
              }}
            >
              <Create
                style={{
                  margin: 0,
                  padding: 0,
                  color: "#fff",
                  height: "18px",
                  width: "18px",
                }}
              />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton
              style={{ margin: 0, padding: 0 }}
              aria-haspopup="true"
              color="inherit"
              aria-controls="delete-row"
              onClick={() => {
                props.setOpenDeleteModal(true);
                props.setId(props.cont_id);
              }}
            >
              <Delete
                style={{
                  margin: 0,
                  padding: 0,
                  color: "#fff",
                  height: "18px",
                  width: "18px",
                }}
              />
            </IconButton>
          </Tooltip>
        </div>
        <CardContent>
          <Typography
            component="div"
            sx={{
              display: "flex",
              alignItems: "center",
              margin: "10px 0 10px 0",
            }}
          >
            <UserAvatar name={props.company_name} />
            <Tooltip title={props.company_name}>
              <small className="cardMainHeading">{props.company_name}</small>
            </Tooltip>
          </Typography>
          <Typography
            sx={{
              mb: "5px",
              display: "flex",
              alignItems: "center",
            }}
            color="text.primary"
          >
            <Person
              style={{ height: "18px", width: "18px", margin: "0 8px" }}
            />
            <small className="cardContent">{props.name}</small>
          </Typography>
          <Typography
            sx={{
              mb: "5px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <LocalPhone
              style={{ height: "18px", width: "18px", margin: "0 8px" }}
            />
            <small className="cardContent">{props.mobile}</small>
          </Typography>
          <Typography
            sx={{
              display: "flex",
              alignItems: "center",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            <Email style={{ height: "18px", width: "18px", margin: "0 8px" }} />
            <small className="cardContent">{props.email_id}</small>
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
}
