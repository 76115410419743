import React, { useEffect, useState } from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  TextField,
  Fade,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import useStyles from "./styles";
import logo from "./quicktikk_logo.png";
import background from "./background2.jpg";
import { useUserDispatch, loginUser } from "../../context/UserContext";
import dayjs from "dayjs";

function Login(props) {
  var classes = useStyles();
  var userDispatch = useUserDispatch();
  var [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState([]);
  const [loginValue, setLoginValue] = useState(null);
  const [passwordValue, setPasswordValue] = useState(null);

  const currentYear = dayjs()

  const fetchData = () => {
    loginUser(
      userDispatch,
      loginValue,
      passwordValue,
      props.history,
      setIsLoading,
      setIsError,
      setError,
    );
  };

  return (
    <Grid container className={classes.container}>
      <div className={classes.formContainer}>
        <div className={classes.form}>
          <img src={logo} alt="logo" className={classes.logotypeImage} />
          <React.Fragment>
            <Typography variant="h2" className={classes.greeting}>
              Sign In
            </Typography>
            <Fade in={isError}>
              <Typography color="secondary" className={classes.errorMessage}>
                {error.length > 0 &&
                  error.map((err) => {
                    return <p>{err.msg}</p>;
                  })}
              </Typography>
            </Fade>
            <TextField
              id="email"
              InputProps={{
                classes: {
                  underline: classes.textFieldUnderline,
                  input: classes.textField,
                },
              }}
              value={loginValue}
              onChange={(e) => setLoginValue(e.target.value)}
              margin="normal"
              placeholder="Username"
              type="text"
              fullWidth
            />
            <TextField
              id="password"
              InputProps={{
                classes: {
                  underline: classes.textFieldUnderline,
                  input: classes.textField,
                },
              }}
              value={passwordValue}
              onChange={(e) => setPasswordValue(e.target.value)}
              margin="normal"
              placeholder="Password"
              type="password"
              fullWidth
            />
            <div className={classes.formButtons}>
              {isLoading ? (
                <CircularProgress size={26} className={classes.loginLoader} />
              ) : (
                <Button
                  onClick={fetchData}
                  variant="contained"
                  color="primary"
                  size="large"
                >
                  Login
                </Button>
              )}
            </div>
          </React.Fragment>
        </div>
        <p className={classes.copyright}>© {currentYear.format('YYYY')} sGate Tech Solutions</p>
      </div>

      <div className={classes.logotypeContainer}>
        <img
          src={background}
          alt="logo"
          className={classes.logotypeContainerImage}
        />
        <div className={classes.logotypeBox}>
          <Typography className={classes.logotypeText}>
            Welcome to <br /> QuickTikk
          </Typography>
          <Typography className={classes.logotypeSubText}>
            Automate your business messaging by using predefined <br /> template
            messages that can be sent anytime from any part <br /> of the world.
          </Typography>
        </div>
      </div>
    </Grid>
  );
}

export default withRouter(Login);
