import React from "react";
import useStyles from "./styles";
import { Typography } from "../Wrappers";

export default function PageTitle(props) {
  var classes = useStyles();

  return (
    <div className={classes.pageTitleContainer}>
      <Typography className={classes.typo} size="small">
        {props.title}
      </Typography>
      <div className={classes.rightDiv}>
        {props.searching && props.searching}
        {props.button && props.button}
      </div>
    </div>
  );
}
