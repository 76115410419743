import { Alert, Snackbar } from "@mui/material";

export default function UserSnackbar(props) {
  const { open, setClose, message, severity } = props;

  return (
    <Snackbar
      className={severity == "success" ? "snackbarSuccess" : "snackbarError"}
      open={open}
      autoHideDuration={3000}
      onClose={() => setClose(false)}
    >
      <Alert
        onClose={() => setClose(false)}
        severity={severity}
        sx={{ width: "fit-content" }}
      >
        {message}!
      </Alert>
    </Snackbar>
  );
}
