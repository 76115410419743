import { Button, Snackbar } from "@material-ui/core";
import PageTitle from "../../../components/PageTitle";
import { useState } from "react";
import UserList from "./userList";
import AddUser from "./addUser";
import axios from "axios";
import env from "../../../env.json";
import { useUserState } from "../../../context/UserContext";
import { Alert } from "@mui/material";

export default function Users() {
  const [showAddClient, setShowAddClient] = useState(false);
  const [clientDetail, setClientDetail] = useState();
  const [addNewClient, setAddNewClient] = useState({
    client_name: null,
    valid_upto: null,
    password: null,
    repeat_password: null,
    username: null,
  });
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const { token } = useUserState();

  const handleSave = () => {
    const requestSaveClient = {
      url: env.BaseURL + `api/admin/editclient`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: clientDetail,
    };
    axios(requestSaveClient)
      .then((response) => {
        if (response.data.status === 200) {
          setClientDetail(null);
          setShowAddClient(false);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleAdd = () => {
    const requestSaveClient = {
      url: env.BaseURL + `api/admin/addclient`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: addNewClient,
    };
    axios(requestSaveClient)
      .then((response) => {
        if (response.data.status === 200) {
          setAddNewClient(null);
          setShowAddClient(false);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <>
      <PageTitle
        title="Clients"
        button={
          !showAddClient ? (
            <Button
              onClick={() => {
                setShowAddClient(true);
              }}
              variant="contained"
              size="small"
              color="primary"
            >
              Create Client
            </Button>
          ) : (
            <div>
              <Button
                onClick={() => {
                  setShowAddClient(false);
                  setClientDetail(null);
                }}
                variant="contained"
                size="small"
                style={{
                  fontWeight: "600",
                  marginRight: "20px",
                  color: "#fff",
                  background: "#ab0f0c",
                }}
              >
                Cancel
              </Button>
              {clientDetail && clientDetail != null ? (
                <Button
                  variant="contained"
                  size="small"
                  style={{
                    color: "#fff",
                    background: "#008c53",
                    fontWeight: "600",
                  }}
                  onClick={() => {
                    if (
                      clientDetail.client_name !== null &&
                      clientDetail.client_name !== "" &&
                      clientDetail.valid_upto !== null &&
                      clientDetail.valid_upto !== "" &&
                      clientDetail.username !== null &&
                      clientDetail.username !== "" &&
                      clientDetail.password === clientDetail.repeat_password
                    ) {
                      handleSave();
                    } else {
                      setOpenSnackbar(true);
                    }
                  }}
                >
                  Update
                </Button>
              ) : (
                <Button
                  variant="contained"
                  size="small"
                  style={{
                    color: "#fff",
                    background: "#008c53",
                    fontWeight: "600",
                  }}
                  onClick={() => {
                    if (
                      addNewClient.client_name !== null &&
                      addNewClient.client_name !== "" &&
                      addNewClient.valid_upto !== null &&
                      addNewClient.valid_upto !== "" &&
                      addNewClient.username !== null &&
                      addNewClient.username !== "" &&
                      addNewClient.password !== null &&
                      addNewClient.password !== "" &&
                      addNewClient.password === addNewClient.repeat_password
                    ) {
                      handleAdd();
                    } else {
                      setOpenSnackbar(true);
                    }
                  }}
                >
                  Add
                </Button>
              )}
            </div>
          )
        }
      />
      {!showAddClient ? (
        <UserList
          setShowAddClient={setShowAddClient}
          setClientDetail={setClientDetail}
        />
      ) : (
        <AddUser
          clientDetail={clientDetail}
          setClientDetail={setClientDetail}
          setAddNewClient={setAddNewClient}
          addNewClient={addNewClient}
        />
      )}
      <Snackbar className="snackbarError"
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert
          onClose={() => setOpenSnackbar(false)}
          severity="error"
        >
          {clientDetail != null ? (
            <>
              {clientDetail?.client_name !== null &&
              clientDetail?.client_name !== ""
                ? clientDetail?.username !== null &&
                  clientDetail?.username !== ""
                  ? clientDetail?.password != clientDetail?.repeat_password
                    ? "Password and Repeat-Password do not match!"
                    : "Valid Date is Required!"
                  : "Username is Required!"
                : "Client Name is Required!"}
            </>
          ) : (
            <>
              {addNewClient?.client_name !== null &&
              addNewClient?.client_name !== ""
                ? addNewClient?.username !== null &&
                  addNewClient?.username !== ""
                  ? addNewClient?.password !== null &&
                    addNewClient?.password !== ""
                    ? addNewClient?.repeat_password !== null &&
                      addNewClient?.repeat_password !== ""
                      ? addNewClient?.password === addNewClient?.repeat_password
                        ? "Valid Date is Required!"
                        : "Password and Repeat-Password do not match!"
                      : "Repeat Password is Required!"
                    : "Password is Required!"
                  : "Username is Required!"
                : "Client Name is Required!"}
            </>
          )}
        </Alert>
      </Snackbar>
    </>
  );
}
