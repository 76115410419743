import React from "react";
import {
  CardContent,
  IconButton,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import { Typography } from "@mui/material";
import { Card } from "@mui/material";
import { Contacts, Create, Delete, LocalPhone } from "@material-ui/icons";
import UserAvatar from "../../../../components/UserAvatar/UserAvatar";
import "./style.css";
import styled from "@emotion/styled";
import ToolTipTable from "./toolTipTable";

export default function ContactCard(props) {
  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({}) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#fff",
      color: "rgba(0, 0, 0, 0.87)",
      fontSize: 12,
      maxWidth: "350px",
      padding: "0",
    },
  }));

  const ContactsTooltip = () => {
    return (
      <div style={{ overflowY: "auto", maxHeight: "40vh" }}>
        <ToolTipTable
          showInfo={props.showInfo}
          viewGroupContactList={props.viewGroupContactList}
          setViewGroupContactList={() => false}
        />
      </div>
    );
  };

  return (
    <div>
      <Card className="shadow" sx={{ width: "100%" }} elevation={3}>
        <CardContent style={{ padding: "5px 16px" }}>
          <div
            style={{
              display: props.index === 1 ? "flex" : "none",
              justifyContent: "end",
            }}
          >
            <LightTooltip className="shadow" title={<ContactsTooltip />}>
              <IconButton
                style={{ margin: "0", padding: 0 }}
                aria-haspopup="true"
                color="inherit"
                aria-describedby={props.id}
                onMouseOver={() => {
                  props.setGetId(props.c_id);
                  props.setShowInfo(true);
                }}
              >
                <Contacts className="infoIcon" />
              </IconButton>
            </LightTooltip>
          </div>
          <Typography
            component="div"
            sx={{
              display: "flex",
              alignItems: "center",
              margin: "5px 0 10px 0",
            }}
          >
            <UserAvatar name={props.name} bgcolor={props.bgcolor} />
            <Tooltip title={props.name}>
              <small className="cardMainHeading">{props.name}</small>
            </Tooltip>
          </Typography>
          {props.mobile_no && (
            <Typography
              sx={{
                mb: "0px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <LocalPhone
                style={{ height: "18px", width: "18px", margin: "0 5px" }}
              />
              <small className="cardContent">{props.mobile_no}</small>
            </Typography>
          )}
          <div
            style={{
              display: props.id === 2 ? "none" : "flex",
              justifyContent: "end",
            }}
          >
            <Tooltip title="Edit">
              <IconButton
                style={{ margin: "0 10px 0 0", padding: 0 }}
                aria-haspopup="true"
                color="inherit"
                aria-describedby={props.id}
                onClick={(event) => {
                  props.setGetId(props.c_id);
                  props.setShowInfo(false);
                  props.setPopoverEvent(event.currentTarget);
                  props.setPopoverDeps(true);
                  props.setIsEdit(true);
                }}
              >
                <Create className="tabIcon" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
              <IconButton
                style={{ margin: 0, padding: 0 }}
                aria-haspopup="true"
                color="inherit"
                aria-controls="delete-row"
                onClick={() => {
                  props.setGetId(props.c_id);
                  props.setOpenDeleteModal(true);
                }}
              >
                <Delete className="tabIcon" />
              </IconButton>
            </Tooltip>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
