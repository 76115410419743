import {
  ArrowDropDown,
  AttachFile,
  CancelOutlined,
  Clear,
  Close,
  Info,
  Loop,
  Schedule,
} from "@material-ui/icons";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CircularProgress,
  FormControl,
  Grid,
  Modal,
  TextField,
  Tooltip,
  createFilterOptions,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import "./style.css";
import env from "../../../../env.json";
import axios from "axios";
import { useUserState } from "../../../../context/UserContext";
import UserPopover from "../../../../components/Popover/UserPopover";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

export default function SendMessage(props) {
  const filterOptions = createFilterOptions({
    ignoreCase: true,    
    limit: 50,
  });
  const {
    index,
    setPopoverDeps,
    setOpenSnackbar,
    setSnackbarMsg,
    setSeverity,
  } = props;
  const { token } = useUserState();
  const [getContactsList, setGetContactsList] = useState([]);
  const [getTemplatesList, setGetTemplatesList] = useState([]);
  const [attachedFile, setAttachedFile] = useState(null);
  const [getSelectedContacts, setGetSelectedContacts] = useState(null);
  const [getSelectedTemplate, setGetSelectedTemplate] = useState(null);
  const [getFrequency, setGetFrequency] = useState(false);
  const [getMessage, setGetMessage] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [scheduleDeps, setScheduleDeps] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [showErrorFreqDateTime, setShowErrorFreqDateTime] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [errorInfo, setErrorInfo] = useState(false);

  const frequencyRef = useRef(null);
  const dateTimeRef = useRef(null);
  const currentDateTime = dayjs();
  const minutesUntilNextMultipleOf5 = 5 - (currentDateTime.minute() % 5);
  const minDateTime = currentDateTime.add(
    minutesUntilNextMultipleOf5,
    "minute",
  );
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    getContactsListFunc();
    getTemplatesListFunc();

    if (!scheduleDeps) {
      setAnchorEl(null);
    }
  }, [scheduleDeps]);

  function getContactsListFunc() {
    if (index === 0) {
      const request = {
        url: env.BaseURL + `api/user/messaging/contacts`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };
      axios(request)
        .then((response) => {
          if (response.data.status === 200) {
            setGetContactsList(response.data.data);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    } else if (index === 1) {
      const request = {
        url: env.BaseURL + `api/user/messaging/contactgroups`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };
      axios(request)
        .then((response) => {
          if (response.data.status === 200) {
            setGetContactsList(response.data.data);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      const requestWaGroup = {
        url: env.BaseURL + `api/user/messaging/whatsappgroups`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };
      axios(requestWaGroup)
        .then((response) => {
          if (response.data.status === 200) {
            setGetContactsList(response.data.data);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  function getTemplatesListFunc() {
    const request = {
      url: env.BaseURL + `api/user/messaging/tamplate`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    axios(request)
      .then((response) => {
        if (response.data.status === 200) {
          setGetTemplatesList(response.data.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const scheduleForm = (
    <>
      <h5
        className="scheduleHeading"
        onClick={() => {
          setScheduleDeps(false);
          setOpenModal(true);
        }}
      >
        <Schedule
          style={{ marginRight: "5px", width: "20px", height: "20px" }}
        />
        Schedule send
      </h5>
    </>
  );

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "fit-content",
    bgcolor: "none",
    border: "none",
    boxShadow: 24,
  };

  const sendMessageFunc = () => {
    if (
      index === 2 &&
      getFrequency &&
      (frequencyRef.current?.value != undefined ||
        frequencyRef.current?.value != "" ||
        frequencyRef.current?.value != null) &&
      (dateTimeRef.current?.value === undefined ||
        dateTimeRef.current?.value === "" ||
        dateTimeRef.current?.value === null)
    ) {
      setShowErrorFreqDateTime(true);
      setOpenModal(true);
    } else {
      setShowLoading(true);
      const dataForm = new FormData();

      if (index === 2) {
        dataForm.append("group_id", getSelectedContacts);
        dataForm.append("temp_id", getSelectedTemplate);
        dataForm.append("msg", getMessage.replace(/"/g, "'"));
        dataForm.append("file", attachedFile);
        dataForm.append(
          "msg_type",
          dateTimeRef.current?.value != null &&
            dateTimeRef.current?.value != undefined
            ? frequencyRef.current?.value != null &&
              frequencyRef.current?.value != undefined
              ? 2
              : 3
            : 1,
        );
        dataForm.append(
          "frequency",
          !getFrequency ? 0 : frequencyRef.current?.value,
        );
        dataForm.append(
          "cron_run_date",
          openModal
            ? dayjs(dateTimeRef.current?.value, "DD/MM/YYYY HH:mm:ss").format(
                "YYYY-MM-DD",
              )
            : null,
        );
        dataForm.append(
          "cron_run_time",
          openModal
            ? dayjs(dateTimeRef.current?.value, "DD/MM/YYYY HH:mm:ss").format(
                "HH:mm",
              )
            : null,
        );

        const request = {
          url: env.BaseURL + `api/user/messaging/wamessages`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: dataForm,
        };
        axios(request)
          .then((response) => {
            if (response.data.status === 200) {
              setPopoverDeps(false);
              setShowLoading(false);
              setSnackbarMsg("Message sent successfully!");
              setSeverity("success");
              setOpenSnackbar(true);
            }
          })
          .catch((error) => {
            console.error(error);
            setShowLoading(false);
            setErrorInfo(true);
            setSnackbarMsg("Something went wrong!");
            setSeverity("error");
            setOpenSnackbar(true);
          });
      } else {
        dataForm.append("group_id", index === 0 ? 0 : getSelectedContacts);
        dataForm.append("cont_ids", index === 0 ? getSelectedContacts : null);
        dataForm.append("temp_id", getSelectedTemplate);
        dataForm.append("msg", getMessage.replace(/"/g, "'"));
        dataForm.append("file", attachedFile);
        dataForm.append(
          "status",
          dateTimeRef.current?.value != null &&
            dateTimeRef.current?.value != undefined
            ? "Save"
            : "Sent",
        );
        dataForm.append(
          "custom_sent_date_time",
          openModal
            ? dayjs(dateTimeRef.current?.value, "DD/MM/YYYY HH:mm:ss").format(
                "YYYY-MM-DD HH:mm",
              )
            : null,
        );

        const request = {
          url: env.BaseURL + `api/user/messaging/messages`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: dataForm,
        };
        axios(request)
          .then((response) => {
            if (response.data.status === 200) {
              setPopoverDeps(false);
              setShowLoading(false);
              setSnackbarMsg("Message sent successfully!");
              setSeverity("success");
              setOpenSnackbar(true);
            }
          })
          .catch((error) => {
            console.error(error);
            setShowLoading(false);
            setErrorInfo(true);
            setSnackbarMsg("Something went wrong!");
            setSeverity("error");
            setOpenSnackbar(true);
          });
      }
    }
  };

  return (
    <>
      <div className="inbox">
        <div className="inboxHead">
          <h3 className="inboxHeading">New Message</h3>
          <div
            onClick={() => {
              setPopoverDeps(false);
            }}
          >
            <Close className="inboxClose" />
          </div>
        </div>
        <div className="inboxTo">
          <p>To</p>
          {index === 0 || index === 2 ? (
            <Autocomplete
              fullWidth
              limitTags={2}
              multiple
              id="tags-standard2"
              size="small"
              options={getContactsList.filter((tag, index, array) => array.findIndex(t => t.name == tag.name ) == index)}
              filterSelectedOptions
              filterOptions={filterOptions}
              getOptionLabel={(option) => option.name}
              onChange={(e, value) => {
                if (index === 0) {
                  setGetSelectedContacts(value.map((val) => val.c_id).join());
                } else {
                  setGetSelectedContacts(value.map((val) => val.id).join());
                }
              }}
              renderInput={(params) => (
                <TextField {...params} variant="standard" />
              )}
            />
          ) : (
            <Autocomplete
              fullWidth
              size="small"
              id="tags-standard"
              options={getContactsList}
              filterSelectedOptions
              getOptionLabel={(option) => option.group_name}
              onChange={(e, value) => {
                setGetSelectedContacts(value?.g_id);
              }}
              renderInput={(params) => (
                <TextField {...params} variant="standard" />
              )}
            />
          )}
        </div>
        <div className="inboxTemplate">
          <p>Template</p>
          <Autocomplete
            fullWidth
            size="small"
            id="tags-standard"
            options={getTemplatesList}
            filterSelectedOptions
            getOptionLabel={(option) => option.temp_name}
            onChange={(e, value) => {
              setGetSelectedTemplate(value?.t_id);
              setGetMessage(value?.temp_body);
            }}
            renderInput={(params) => (
              <TextField {...params} variant="standard" />
            )}
          />
        </div>
        <div className="inboxText">
          <TextField
            value={getMessage}
            className="inboxTextField"
            multiline
            minRows={8}
            onChange={(e) => {
              setGetMessage(e.target.value);
            }}
          />
        </div>
        {attachedFile != null ? (
          <div className="inboxAttachment">
            <small>{attachedFile && attachedFile.name}</small>
            <div onClick={() => setAttachedFile(null)}>
              <Clear className="inboxClose" />
            </div>
          </div>
        ) : null}
        <div className="inboxBottom">
          <div className="sendBtn">
            <CircularProgress
              style={{
                width: "20px",
                height: "20px",
                color: "#fff",
                marginLeft: "10px",
                display: !showLoading ? "none" : "flex",
              }}
            />
            <button
              style={{
                padding: !showLoading
                  ? "10px 10px 10px 16px"
                  : "10px 10px 10px 5px",
              }}
              onClick={() => {
                if (
                  getSelectedContacts != null &&
                  getSelectedContacts != undefined &&
                  getSelectedContacts != ""
                ) {
                  if (
                    getMessage != null &&
                    getMessage != undefined &&
                    getMessage != ""
                  ) {
                    if (
                      getFrequency &&
                      (frequencyRef.current?.value === undefined ||
                        frequencyRef.current?.value === "" ||
                        frequencyRef.current?.value === null)
                    ) {
                      setOpenModal(true);
                    } else {
                      sendMessageFunc();
                    }
                  } else {
                    setOpenModal(true);
                  }
                } else {
                  setOpenModal(true);
                }
              }}
            >
              Send
            </button>
            <div className="buttonDivider"></div>
            <div
              aria-describedby={id}
              onClick={(event) => {
                setShowErrorFreqDateTime(false);
                setAnchorEl(event.currentTarget);
                setScheduleDeps(true);
              }}
            >
              <ArrowDropDown
                style={{
                  color: "#fff",
                  width: "25px",
                  height: "25px",
                  margin: "0 8px 0 3px",
                  cursor: "pointer",
                }}
              />
            </div>
          </div>
          <div className="inboxBottomRight">
            {index === 2 ? (
              <>
                {!getFrequency ? (
                  <h5
                    className="scheduleHeading"
                    onClick={() => {
                      setGetFrequency(true);
                    }}
                  >
                    <Loop
                      style={{
                        marginRight: "5px",
                        width: "20px",
                        height: "20px",
                      }}
                    />
                    Recurring Message
                  </h5>
                ) : (
                  <Grid xs={4} style={{ position: "relative" }}>
                    <FormControl fullWidth>
                      <TextField
                        size="small"
                        name="frequency"
                        type="number"
                        required
                        label="Frequency"
                        inputRef={frequencyRef}
                      />
                    </FormControl>
                    <CancelOutlined
                      onClick={() => {
                        setGetFrequency(false);
                      }}
                      className="clearFrequency"
                    />
                  </Grid>
                )}
              </>
            ) : null}
            <Tooltip title="Attach File">
              <label
                htmlFor="imageInput"
                style={{ display: "inline-block", marginLeft: "10px" }}
              >
                <AttachFile className="inboxClose" />
                <input
                  type="file"
                  onChange={(event) => {
                    setAttachedFile(event.target.files[0]);
                  }}
                  style={{ display: "none" }}
                  id="imageInput"
                />
              </label>
            </Tooltip>
          </div>
        </div>
      </div>

      <UserPopover
        id={id}
        open={open}
        form={scheduleForm}
        setOpen={setScheduleDeps}
        popoverEvent={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      />

      <Modal
        open={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Card className="scheduleCard">
            <div className="scheduleCardHead">
              <h3>
                {getSelectedContacts != null &&
                getSelectedContacts != "" &&
                getSelectedContacts != undefined &&
                getMessage != null &&
                getMessage != undefined &&
                getMessage != ""
                  ? index === 2 &&
                    getFrequency &&
                    (frequencyRef.current?.value === undefined ||
                      frequencyRef.current?.value === "")
                    ? "Error"
                    : showErrorFreqDateTime
                    ? "Error"
                    : "Schedule Send"
                  : "Error"}
              </h3>
              <Close
                onClick={() => {
                  setOpenModal(false);
                }}
              />
            </div>
            {getSelectedContacts != null &&
            getSelectedContacts != "" &&
            getSelectedContacts != undefined ? (
              getMessage != null &&
              getMessage != "" &&
              getMessage != undefined ? (
                index === 2 &&
                getFrequency &&
                (frequencyRef.current?.value === undefined ||
                  frequencyRef.current?.value === "") ? (
                  <p>Please specify frequency `{">"}` or = 1.</p>
                ) : showErrorFreqDateTime ? (
                  <p>Please specify date and time to send message.</p>
                ) : (
                  <>
                    <p>India Standard Time</p>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <MobileDateTimePicker
                        defaultValue={minDateTime}
                        minDateTime={minDateTime}
                        minutesStep={5}
                        format="DD/MM/YYYY HH:mm:ss"
                        inputRef={dateTimeRef}
                      />
                    </LocalizationProvider>
                  </>
                )
              ) : (
                <p>Please specify at least one template or custom message.</p>
              )
            ) : (
              <p>Please specify at least one recipient.</p>
            )}
            <div className="scheduleFooter">
              <Button
                variant="contained"
                onClick={() => {
                  if (
                    getSelectedContacts != null &&
                    getSelectedContacts != undefined &&
                    getSelectedContacts != "" &&
                    getMessage != "" &&
                    getMessage != undefined &&
                    getMessage != null
                  ) {
                    if (
                      index === 2 &&
                      getFrequency &&
                      (frequencyRef.current?.value === null ||
                        frequencyRef.current?.value === "")
                    ) {
                      setOpenModal(false);
                    } else {
                      if (showErrorFreqDateTime) {
                        setOpenModal(false);
                      } else {
                        setOpenModal(false);
                        sendMessageFunc();
                        setScheduleDeps(false);
                      }
                    }
                  } else {
                    setOpenModal(false);
                  }
                }}
              >
                {getSelectedContacts != null &&
                getSelectedContacts != undefined &&
                getSelectedContacts != "" &&
                getMessage != "" &&
                getMessage != undefined &&
                getMessage != null
                  ? index === 2 &&
                    getFrequency &&
                    (frequencyRef.current?.value === undefined ||
                      frequencyRef.current?.value === "")
                    ? "OK"
                    : showErrorFreqDateTime
                    ? "OK"
                    : "Send"
                  : "Ok"}
              </Button>
            </div>
          </Card>
        </Box>
      </Modal>
    </>
  );
}
