import { AddCircleOutline } from "@material-ui/icons";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import env from "../../../../env.json";
import { useUserState } from "../../../../context/UserContext";
import axios from "axios";
import UserPopover from "../../../../components/Popover/UserPopover";
import AddCompany from "./addCompany";

export default function ContactDetails(props) {
  const { token } = useUserState();
  const {
    data,
    setContactOpen,
    offset,
    setOpenSnackbar,
    setSnackbarMsg,
    setSnackbarSeverity
  } = props;
  const [getCompanyList, setGetCompanyList] = useState([]);
  const [companyPopoverEvent, setCompanyPopoverEvent] = useState(null);
  const [companyDeps, setCompanyDeps] = useState(false);
  const [errorList, setErrorList] = useState([]);

  const nameFieldRef = useRef();
  const mobileFieldRef = useRef();
  const companyFieldRef = useRef();
  const emailFieldRef = useRef();

  const open = Boolean(companyPopoverEvent);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    getCompanyListFunc();

    if (!companyDeps) {
      setCompanyPopoverEvent(null);
    }
  }, [companyDeps]);

  const getCompanyListFunc = () => {
    const requestCompanies = {
      url: env.BaseURL + `api/user/payment/companies`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    axios(requestCompanies)
      .then((response) => {
        if (response.data.status === 200) {
          setGetCompanyList(response.data.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  function updateContact() {
    const request = {
      url: env.BaseURL + `api/user/payment/contacts?offset=${offset}`,
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        cont_id: data.cont_id,
        cpy_id: companyFieldRef.current?.value,
        name: nameFieldRef.current?.value,
        email_id: emailFieldRef.current?.value,
        mobile: mobileFieldRef.current?.value,
      },
    };
    axios(request)
      .then((res) => {
        if (res.data.status === 200) {
          setContactOpen(false);
          setSnackbarSeverity("success")
          setSnackbarMsg("Contact updated successfully");
          setOpenSnackbar(true);
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.status === 406) {
          setErrorList(err.response.data.errors);
        } else {
          setErrorList([
            {
              msg: "Something went Wrong.",
              path: "",
            },
          ]);
        }
      });
  }

  function saveContact() {
    const request = {
      url: env.BaseURL + `api/user/payment/contacts`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        cpy_id: companyFieldRef.current?.value
          ? companyFieldRef.current?.value
          : "",
        name: nameFieldRef.current?.value,
        email_id: emailFieldRef.current?.value,
        mobile: mobileFieldRef.current?.value,
      },
    };
    axios(request)
      .then((res) => {
        if (res.data.status === 200) {
          setContactOpen(false);
          setSnackbarSeverity("success")
          setSnackbarMsg("Contact added successfully");
          setOpenSnackbar(true);
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.status === 406) {
          setErrorList(err.response.data.errors);
        } else {
          setErrorList([
            {
              msg: "Something went Wrong.",
              path: "",
            },
          ]);
        }
      });
  }

  const companyForm = <AddCompany setCompanyDeps={setCompanyDeps} />;

  return (
    <>
      <div
        style={{
          padding: "15px 15px 20px",
          margin: "10px 15px",
          maxWidth: "420px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "15px",
          }}
        >
          <h3
            style={{
              color: "#4f4e4e",
              fontWeight: "500",
              margin: 0,
            }}
          >
            {data != null ? "Update Details" : "Create New Contact"}
          </h3>
          {data != null ? null : (
            <>
              <Button
                aria-describedby={id}
                onClick={(e) => {
                  setCompanyPopoverEvent(e.currentTarget);
                  setCompanyDeps(true);
                }}
                style={{ fontWeight: "600", fontSize: "12px" }}
              >
                <AddCircleOutline />
                Add Company
              </Button>
            </>
          )}
        </div>
        <p className="errorMsgCommon">
          {errorList.length > 0 &&
            errorList.map((err) => {
              if (err.path === "") return <small>{err.msg}!</small>;
            })}
        </p>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel id="demo-select-small-label">Company Name</InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                size="small"
                label="Company Name"
                inputRef={companyFieldRef}
                defaultValue={data?.cpy_id}
              >
                {getCompanyList.map((val) => {
                  return (
                    <MenuItem key={val.cpy_id} value={val.cpy_id}>
                      {val.cpy_name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <p className="errorMsg">
              {errorList.length > 0 &&
                errorList.map((err) => {
                  if (err.path === "cpy_id") return <small>{err.msg}!</small>;
                })}
            </p>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <TextField
                size="small"
                name="name"
                required
                label="Name"
                defaultValue={data?.name}
                inputRef={nameFieldRef}
              />
            </FormControl>
            <p className="errorMsg">
              {errorList.length > 0 &&
                errorList.map((err) => {
                  if (err.path === "name") return <small>{err.msg}!</small>;
                })}
            </p>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <TextField
                size="small"
                name="mobile"
                label="Mobile"
                required
                defaultValue={data?.mobile}
                inputRef={mobileFieldRef}
              />
            </FormControl>
            <p className="errorMsg">
              {errorList.length > 0 &&
                errorList.map((err) => {
                  if (err.path === "mobile") return <small>{err.msg}!</small>;
                })}
            </p>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <TextField
                size="small"
                name="email_id"
                label="Email"
                defaultValue={data?.email_id}
                inputRef={emailFieldRef}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid mt={2} mb={2} style={{ float: "right" }}>
          <Button
            style={{
              fontWeight: "600",
              marginRight: "20px",
              color: "#ab0f0c",
            }}
            size="small"
            onClick={() => {
              setContactOpen(false);
            }}
          >
            Cancel
          </Button>
          <Button
            size="small"
            style={{
              color: "#008c53",
              fontWeight: "600",
            }}
            onClick={() => {
              data != null ? updateContact() : saveContact();
            }}
          >
            {data != null ? "Update" : "Save"}
          </Button>
        </Grid>
      </div>
      <UserPopover
        id={id}
        open={open}
        form={companyForm}
        setOpen={setCompanyDeps}
        popoverEvent={companyPopoverEvent}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      />
    </>
  );
}
