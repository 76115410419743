import { Button, Grid } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { InputBase } from "@material-ui/core";
import { Search as SearchIcon } from "@material-ui/icons";
import env from "../../../../env.json";
import { useUserState } from "../../../../context/UserContext";
import DeleteModal from "../../../../components/DeleteModal/DeleteModal";
import axios from "axios";
import ContactCard from "./contactCard";
import UserPopover from "../../../../components/Popover/UserPopover";
import ContactGroupForm from "./contactGroupForm";
import UserSnackbar from "../../../../components/Snackbar.js/UserSnackbar";

export default function ContactGroups(props) {
  const { children, value, index, ...other } = props;
  const { token } = useUserState();
  const [timer, setTimer] = useState();
  const [isSearchOpen, setSearchOpen] = useState(false);
  const searchKeyRef = useRef();
  const [contacts, setContacts] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteDone, setDeleteDone] = useState(false);
  const [getContactId, setGetContactId] = useState(null);
  const [popoverEvent, setPopoverEvent] = useState(null);
  const [popoverDeps, setPopoverDeps] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editContact, setEditContact] = useState(null);
  const [viewGroupContactList, setViewGroupContactList] = useState([]);
  const [showInfo, setShowInfo] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState(null);
  const [snackbarSeverity, setSnackbarSeverity] = useState(null);

  const open = Boolean(popoverEvent);
  const id = open ? "popover-textfield" : undefined;

  useEffect(() => {
    if (value === index) getContacts();

    if (deleteDone) {
      setOpenDeleteModal(false);
      deleteContact(getContactId);
    }

    if (!popoverDeps) {
      setPopoverEvent(null);
      setIsEdit(false);
    }
  }, [deleteDone, popoverDeps, value]);

  useEffect(() => {
    if (!isEdit) {
      setEditContact(null);
    } else {
      setEditContact(contacts.find((val) => val.g_id === getContactId));
    }

    if (getContactId != null) {
      viewGroupContactsFunc(getContactId);
    }
  }, [isEdit, getContactId]);

  const handelSearching = async () => {
    clearTimeout(timer);
    const searchKey = searchKeyRef.current?.value;
    if (searchKey.length > 0) {
      let key = setTimeout(() => {
        const requestContacts = {
          url:
            env.BaseURL +
            `api/user/messaging/contactgroups/searching?value=${searchKey}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        };
        axios(requestContacts)
          .then((response) => {
            setSearchOpen(true);
            if (response.data.status === 200) {
              setContacts(response.data.data);
            } else {
              setContacts([]);
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }, 500);
      setTimer(key);
    } else {
      setSearchOpen(false);
      setTimeout(() => {
        getContacts(true);
      }, 100);
    }
  };
  function getContacts(zero = false) {
    const request = {
      url:
        !zero && isSearchOpen
          ? env.BaseURL +
            `api/user/messaging/contactgroups/searching?value=${searchKeyRef.current?.value}`
          : env.BaseURL + `api/user/messaging/contactgroups`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    axios(request)
      .then((response) => {
        if (response.data.status === 200) {
          setContacts(response.data.data);
        } else {
          setContacts([]);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function deleteContact(id) {
    const request = {
      url: env.BaseURL + `api/user/messaging/contactgroups`,
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        g_id: id,
      },
    };
    axios(request)
      .then((res) => {
        setDeleteDone(false);
        getContacts();
        setGetContactId(null);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const viewGroupContactsFunc = async(id) => {
    const request = {
      url: env.BaseURL + `api/user/messaging/viewcontacts`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        g_id: id,
      },
    };
   await axios(request)
      .then((res) => {
        if (res.data.status === 200) {
          setViewGroupContactList(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const groupContactForm = (
    <ContactGroupForm
      setOpen={setPopoverDeps}
      showInfo={showInfo}
      isEdit={isEdit}
      data={editContact}
      setSnackbarSeverity={setSnackbarSeverity}
      setSnackbarMsg={setSnackbarMsg}
      setOpenSnackbar={setOpenSnackbar}
      viewGroupContactList={viewGroupContactList}
      setViewGroupContactList={setViewGroupContactList}
    />
  );


  return (
    value === index && (
      <>
        <Grid container style={{ padding: "5px 30px" }} spacing={3}>
          <Grid item md={6} xs={6}>
            <Button
              size="small"
              variant="contained"
              aria-describedby={id}
              onClick={(event) => {
                setPopoverEvent(event.currentTarget);
                setPopoverDeps(true);
                setGetContactId(null);
              }}
            >
              Create Group
            </Button>
          </Grid>
          <Grid item md={6} xs={6}>
            <div className="search">
              <SearchIcon className="searchIcon" />
              <InputBase
                inputRef={searchKeyRef}
                onChange={handelSearching}
                placeholder="Search…"
                className="searchInput"
              />
            </div>
          </Grid>
        </Grid>
        <Grid
          role="tabpanel"
          hidden={value !== index}
          id={`simple-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          container
          spacing={3}
          style={{
            padding: "0px 30px 30px",
            marginTop: "5px",
            overflowY: "auto",
            minHeight: "10vh",
            maxHeight: "67vh",
          }}
        >
          {contacts.length > 0 ? (
            contacts.map(
              (value) =>
                value.group_name && (
                  <Grid key={value.g_id} item xs={12} sm={3.9} md={3}>
                    <ContactCard
                      index={index}
                      name={value.group_name}
                      c_id={value.g_id}
                      id={id}
                      setIsEdit={setIsEdit}
                      showInfo={showInfo}
                      setShowInfo={setShowInfo}
                      setGetId={setGetContactId}
                      setPopoverEvent={setPopoverEvent}
                      setPopoverDeps={setPopoverDeps}
                      setOpenDeleteModal={setOpenDeleteModal}
                      viewGroupContactList={viewGroupContactList}
                    />
                  </Grid>
                ),
            )
          ) : (
            <p style={{ marginTop: "20%", marginLeft: "45%" }}>No data found</p>
          )}
        </Grid>
        <DeleteModal
          open={openDeleteModal}
          setOpen={setOpenDeleteModal}
          setDone={setDeleteDone}
        />
        <UserPopover
          id={id}
          open={open}
          form={groupContactForm}
          setOpen={setPopoverDeps}
          popoverEvent={popoverEvent}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        />
        <UserSnackbar
          severity={snackbarSeverity}
          open={openSnackbar}
          setClose={setOpenSnackbar}
          message={snackbarMsg}
        />
      </>
    )
  );
}
