import { Popover } from "@material-ui/core";
import "./style.css";

export default function UserPopover(props) {
  const {
    open,
    id,
    popoverEvent,
    setOpen,
    anchorOrigin,
    transformOrigin,
  } = props;
  const Form = props.form;

  return (
    <>
      <Popover
        id={id}
        open={open}
        anchorEl={popoverEvent}
        onClose={() => {
          setOpen(false);
        }}
        className="shadow"
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      >
        {props.form}
      </Popover>
    </>
  );
}
