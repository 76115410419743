import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  root: {
    display: "flex",
    maxWidth: "100vw",
    overflow: "hidden",
    backgroundColor: "#f3f3f4",
    position: "relative",
  },
  content: {
    flexGrow: 1,
    paddingBottom: theme.spacing(3),
    width: `calc(100vw - 240px)`,
    minHeight: "100vh",
  },
  contentShift: {
    width: `calc(100vw - ${240 + theme.spacing(6)}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  fakeToolbar: {
    ...theme.mixins.toolbar,
  },
  link: {
    "&:not(:first-child)": {
      paddingLeft: 15,
    },
  },
  footer: {
    position: "absolute",
    bottom: "0",
    background: "#fff",
    border: "1px solid #e7eaec",
    padding: "7px",
    fontSize: "12px",
  },
}));
