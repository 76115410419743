import { Button, Grid, Pagination } from "@mui/material";
import { useEffect, useState, useRef } from "react";
import { InputBase } from "@material-ui/core";
import { Search as SearchIcon } from "@material-ui/icons";
import env from "../../../../env.json";
import { useUserState } from "../../../../context/UserContext";
import axios from "axios";
import ContactCard from "./contactCard";

export default function WhatsappGroups(props) {
  const { children, value, index, ...other } = props;
  const { token } = useUserState();
  const [timer, setTimer] = useState();
  const [offset, setOffset] = useState(0);
  const [itemCount, setItemCount] = useState(0);
  const [isSearchOpen, setSearchOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [contacts, setContacts] = useState([]);

  const searchKeyRef = useRef();

  useEffect(() => {
    if (value === index) getContacts();
  }, [value, offset]);

  const handelSearching = async () => {
    setIsLoading(true);
    clearTimeout(timer);
    const searchKey = searchKeyRef.current?.value;
    if (searchKey.length > 0) {
      let key = setTimeout(() => {
        const requestContacts = {
          url:
            env.BaseURL +
            `api/user/messaging/whatsappgroups/searching?offset=${offset}&value=${searchKey}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        };
        axios(requestContacts)
          .then((response) => {
            setIsLoading(false);
            setOffset(0);
            setSearchOpen(true);
            setItemCount(0);
            if (response.data.status === 200) {
              setContacts(response.data.data);
              setItemCount(parseInt(response.data.count));
            } else {
              setContacts([]);
            }
          })
          .catch((error) => {
            setIsLoading(false);
            console.error(error);
          });
      }, 500);
      setTimer(key);
    } else {
      setSearchOpen(false);
      setOffset(0);
      setTimeout(() => {
        getContacts(true);
      }, 100);
    }
  };

  function getContacts(zero = false) {
    setIsLoading(true);
    const requestCompanies = {
      url:
        !zero && isSearchOpen
          ? env.BaseURL +
            `api/user/messaging/whatsappgroups/searching?offset=${offset}&value=${searchKeyRef.current?.value}`
          : env.BaseURL + `api/user/messaging/whatsappgroups?offset=${offset}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    axios(requestCompanies)
      .then((response) => {
        setIsLoading(false);
        if (response.data.status === 200) {
          setContacts(response.data.data);
          setItemCount(parseInt(response.data.count));
        } else {
          setContacts([]);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error(error);
      });
  }

  return (
    value === index && (
      <>
        <Grid style={{ padding: "0px 20px 5px 30px" }} container spacing={2}>
          <Grid item xs={6} sm={6} md={3}>
            <Button
              size="small"
              variant="contained"
              onClick={() => {
                getContacts();
              }}
            >
              Refresh List
            </Button>
          </Grid>
          <Grid
            item
            xs={6}
            sm={6}
            md={4}
            style={{ display: "flex", justifyContent: "end" }}
          >
            <div className="search">
              <SearchIcon className="searchIcon" />
              <InputBase
                inputRef={searchKeyRef}
                onChange={handelSearching}
                placeholder="Search…"
                className="searchInput"
              />
            </div>
          </Grid>
          {contacts.length > 0 && (
            <Grid item xs={12} sm={12} md={5}>
              <Pagination
                count={itemCount}
                onChange={(e, v) => setOffset((v - 1) * 20)}
                color="primary"
                style={{ float: "right", margin: 0 }}
              />
            </Grid>
          )}
        </Grid>
        {isLoading && (
          <div class="loader">
            <div class="loader-circle"></div>
            <span class="loader-text">Loading...</span>
          </div>
        )}
        {!isLoading && (
          <Grid
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            container
            spacing={3}
            style={{
              padding: "0px 30px 30px",
              marginTop: "5px",
              overflowY: "auto",
              minHeight: "10vh",
              maxHeight: "68vh",
            }}
          >
            {contacts.length > 0 ? (
              contacts.map((value) => (
                <Grid item xs={12} sm={3.9} md={3}>
                  {value.name && (
                    <ContactCard
                      id={index}
                      name={value.name}
                      bgcolor="#008069"
                    />
                  )}
                </Grid>
              ))
            ) : (
              <p style={{ marginLeft: "45%", marginTop: "20%" }}>
                No data found
              </p>
            )}
          </Grid>
        )}
      </>
    )
  );
}
