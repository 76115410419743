import { Dialog, DialogContent, Grid } from "@material-ui/core";
import { Cancel, Settings } from "@material-ui/icons";
import "./style.css";
import { Box, Modal } from "@mui/material";

export default function QrCodeModal(props) {
  const { qrImage, open, setOpen } = props;

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 900,
    background: "#fff",
    border: "none",
    borderRadius: "10px",
    padding: "30px",
  };

  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        maxWidth="900px"
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box style={style}>
          <Cancel
            onClick={() => setOpen(false)}
            style={{
              cursor: "pointer",
              right: "5px",
              top: "5px",
              position: "absolute",
            }}
          />
          <Grid
            container
            className="qrmain"
            sx={{ overflowX: "hidden" }}
            spacing={2}
          >
            <Grid item className="qrpints" xs={12} sm={6} md={6}>
              <h3 className="pointheding">Use WhatsApp on your computer</h3>
              <p>1) Open WhatsApp on your phone</p>
              <p>
                2) Tap
                <b> Menu ⋮ </b>
                or{" "}
                <b>
                  Settings <Settings />{" "}
                </b>
                and select <b>Linked Devices </b>
              </p>
              <p>
                3) Tap on <b>Link a device</b>
              </p>
              <p>4) Point your phone to this screen to capture the QR code</p>
            </Grid>
            <Grid item className="qrimage" xs={12} sm={6} md={6}>
              <img src={qrImage} style={{ width: "100%" }} />
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
}
