import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import classnames from "classnames";
import { Box } from "@material-ui/core";
import useStyles from "./styles";
import Header from "../Header";
import Sidebar from "../Sidebar";
import Dashboard from "../../pages/admin/dashboard/Dashboard";
import Modules from "../../pages/admin/adminModules/Modules";
import { useLayoutState } from "../../context/LayoutContext";
import Users from "../../pages/admin/users/user";
import TemplatePayment from "../../pages/users/paymentModule/templates/tempates.payment";
import Settings from "../../pages/users/settings/Settings";
import Contacts from "../../pages/users/paymentModule/contacts/contacts";
import Invoice from "../../pages/users/paymentModule/invoice/Invoice";
import TemplateGroupMessage from "../../pages/users/groupMessagingModule/templates/tempates";
import ContactTabs from "../../pages/users/groupMessagingModule/contacts/tab";
import UserDashboard from "../../pages/users/dashboard";
import Apidoc from "../../pages/users/apidoc/apidoc";
import dayjs from "dayjs";

function Layout(props) {
  const classes = useStyles();
  const { user, token } = props;
  var layoutState = useLayoutState();

  const currentYear = dayjs();

  return (
    <div className={classes.root}>
      <>
        <Sidebar user={user} token={token} />
        <Header
          user={user}
          token={token}
          history={props.history}
          message={props.message}
          setMessage={props.setMessage}
          setIsQrScan={props.setIsQrScan}
        />
        <div
          className={classnames(classes.content, {
            [classes.contentShift]: layoutState.isSidebarOpened,
          })}
        >
          <div className={classes.fakeToolbar} />
          <Switch>
            {user.is_admin ? (
              <>
                <Route path="/app/dashboard" component={Dashboard} />
                <Route path="/app/modules" component={Modules} />
                <Route path="/app/users" component={Users} />
              </>
            ) : (
              <>
                <Route path="/app/payment/contacts" component={Contacts} />
                <Route path="/app/dashboard" component={UserDashboard} />
                <Route
                  path="/app/groupmesaaging/contacts"
                  render={(routeProps) => (
                    <ContactTabs
                      {...routeProps}
                      isQrScan={props.isQrScan}
                      qrMessage={props.message}
                    />
                  )}
                />
                <Route
                  path="/app/payment/invoice"
                  render={(routeProps) => (
                    <Invoice
                      {...routeProps}
                      isQrScan={props.isQrScan}
                      qrMessage={props.message}
                    />
                  )}
                />
                <Route
                  path="/app/payment/template"
                  component={TemplatePayment}
                />
                <Route
                  path="/app/groupmesaaging/templates"
                  component={TemplateGroupMessage}
                />
                <Route path="/app/settings" component={Settings} />
                <Route path="/app/apidocumentation" component={Apidoc} />
              </>
            )}
          </Switch>
        </div>
      </>
      <Box
        width={"100%"}
        display={"flex"}
        alignItems={"center"}
        justifyContent="center"
        className={classes.footer}
      >
        <span style={{ fontWeight: "600" }}>COPYRIGHT </span> &nbsp; SGATE TECH
        SOLUTIONS © {currentYear.format("YYYY")}
      </Box>
    </div>
  );
}

export default withRouter(Layout);
