import { Button, Card, FormControl, Grid, TextField } from "@material-ui/core";
import { useUserState } from "../../../../context/UserContext";
import { useEffect, useState } from "react";
import env from "../../../../env.json";
import axios from "axios";

export default function ReceiveAmount(props) {
  const { token } = useUserState();
  const {
    data,
    offset,
    setOpen,
    setSnackbarMsg,
    setSnackbarSeverity,
    setOpenSnackbar,
  } = props;
  const [balanceAmt, setBalanceAmt] = useState(0);
  const [receivedAmt, setReceivedAmt] = useState(0);
  const [errorList, setErrorList] = useState([]);

  useEffect(() => {
    const calculatedBalanceAmt = parseFloat(data?.total_amount) - receivedAmt;
    setBalanceAmt(calculatedBalanceAmt);
  }, [receivedAmt]);

  const updateAmountFunc = () => {
    const request = {
      url: env.BaseURL + `api/user/receipt?offset=${offset}`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        inv_id: data?.id,
        amount: receivedAmt,
      },
    };
    axios(request)
      .then((res) => {
        if (res.data.status === 200) {
          setOpen(false);
          setSnackbarSeverity("success");
          setSnackbarMsg("Invoice Amount Updated successfully");
          setOpenSnackbar(true);
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.status === 406) {
          setErrorList(err.response.data.errors);
        } else {
          setErrorList([
            {
              msg: "Something went Wrong.",
              path: "",
            },
          ]);
        }
      });
  };

  return (
    <>
      <Card style={{ padding: "20px", minWidth: "50vw", maxWidth: "80vh" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <h3>Add Receipt</h3>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <Button onClick={() => setOpen(false)} style={{ color: "#ab0f0c" }}>
              Cancel
            </Button>
            <Button onClick={updateAmountFunc} style={{ color: "#008c53" }}>
              Save
            </Button>
          </div>
        </div>
        <Grid container spacing={2} mb={1}>
          <Grid item xs={12} sm={6} md={4}>
            <FormControl fullWidth>
              <TextField
                size="small"
                required
                label="Total Amount"
                InputProps={{ inputProps: { min: "1" } }}
                type="number"
                value={data?.total_amount}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormControl fullWidth>
              <TextField
                size="small"
                required
                label="Received Amount"
                placeholder="Enter Received Amt."
                InputProps={{ inputProps: { min: "1" } }}
                type="number"
                onChange={(e) => setReceivedAmt(e.target.value)}
              />
            </FormControl>
            <p className="errorMsg">
              {errorList.length > 0 &&
                errorList.map((err) => {
                  if (err.path === "amount") return <small>{err.msg}!</small>;
                })}
            </p>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormControl fullWidth>
              <TextField
                size="small"
                required
                label="Balance Amount"
                InputProps={{ inputProps: { min: "1" } }}
                type="number"
                value={balanceAmt}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Card>
    </>
  );
}
